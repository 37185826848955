/**
 * Audit Log Actions
 */
import {
    GET_AUDIT_LOG,
    AUDIT_LOG_FAILURE,
    AUDIT_LOG_SUCCESS,
    FILTER_AUDIT_LOG_BY_CONSENT,
    FILTER_AUDIT_LOG_BY_DOMAIN,
    FILTER_AUDIT_LOG_BY_TYPE
} from '../actions/types';

/**
 * Redux Action To get the Audit Log for the logged in user
 */
export const getAuditLog = () => ({
    type: GET_AUDIT_LOG,
});

/**
 * Redux Action Audit Log Success
 */
export const auditLogSuccess = (log) => ({
    type: AUDIT_LOG_SUCCESS,
    payload: log
});


/**
 * Redux Action Audit Log Failure
 */
export const auditLogFailure = (error) => ({
    type: AUDIT_LOG_FAILURE,
    payload: error
})


/**
 * Redux Action On filter Audit Log by domain
 */
export const filterAuditLogByDomain = (searchText) => ({
    type: FILTER_AUDIT_LOG_BY_DOMAIN,
    payload: searchText
});


/**
 * Redux Action On filter Audit Log by domain
 */
export const filterAuditLogByConsent = (searchText) => ({
    type: FILTER_AUDIT_LOG_BY_CONSENT,
    payload: searchText
});

/**
 * Redux Action On filter Audit Log by type
 */
export const filterAuditLogByType = (consentType) => ({
    type: FILTER_AUDIT_LOG_BY_TYPE,
    payload: consentType
});
