import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages"

const SettingList = (props) => {
    
   const languageList = props.languageList.map((lang, index) => {
        return (
            <li id="menu-index" key={index}>
                <div className="align-items-lang color-main" onClick={() => {props.onChange(lang)}}>
                    <img  src={require(`../../../../assets/flag-icons/${lang.icon}.png`)} />
                    <span> {lang.name}</span>
                </div>
            </li>
        )
    })
    return (
        <>
            <div className="setting-title">
                <h1>Settings</h1>
                <div onClick={props.toggle} className="setting-hide">
                    <i className="fa fa-times"></i>
                </div>
            </div>
            <div className="menu-page">
                <ul className="menu-list bottom-20 color-main">
                    {languageList}
                </ul>
            </div>
        </>
    )

}

export default SettingList