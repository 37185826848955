/**
 * App Redux Action Types
 */
export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const DARK_MODE = 'DARK_MODE';
export const BOXED_LAYOUT = 'BOXED_LAYOUT';
export const RTL_LAYOUT = 'RTL_LAYOUT';
export const MINI_SIDEBAR = 'MINI_SIDEBAR';
export const SEARCH_FORM_ENABLE = 'SEARCH_FORM_ENABLE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const TOGGLE_SIDEBAR_IMAGE = 'TOGGLE_SIDEBAR_IMAGE';
export const SET_SIDEBAR_IMAGE = 'SET_SIDEBAR_IMAGE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const START_USER_TOUR = 'START_USER_TOUR';
export const STOP_USER_TOUR = 'STOP_USER_TOUR';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';
// Manage Consent Actions
export const GET_CURRENT = 'GET_CURRENT';
export const DOMAIN_SELECTED = 'DOMAIN_SELECTED';
export const GET_DOMAINS = 'GET_DOMAINS';
export const GET_DOMAINS_SUCCESS = 'GET_DOMAINS_SUCCESS';
export const GET_DOMAINS_FAILURE = 'GET_DOMAINS_FAILURE';
export const GET_CONSENT_SUCCESS = 'GET_CONSENT_SUCCESS';
export const GET_CONSENT_FAILURE = 'GET_CONSENT_FAILURE';
export const FETCH_DOMAINS = 'FETCH_DOMAINS';
export const ON_SELECT_DOMAIN = 'ON_SELECT_DOMAIN';
export const GET_FILTER_DOMAINS = 'GET_FILTER_DOMAINS';
export const GET_ALL_CONSENTS = 'GET_ALL_CONSENTS';
export const GET_DOMAIN_CONSENTS = 'GET_DOMAIN_CONSENTS';
export const GET_SELECTED_CONSENTS = 'GET_SELECTED_CONSENTS';
export const GET_UNSELECTED_CONSENTS = 'GET_UNSELECTED_CONSENTS';
export const GET_FILTER_CONSENTS = 'GET_FILTER_CONSENTS';
export const ON_CHECK_BOX_TOGGLE_CONSENT_ITEM = 'ON_CHECK_BOX_TOGGLE_CONSENT_ITEM';
export const SELECT_ALL_CONSENTS = 'SELECT_ALL_CONSENTS';
export const GET_UNSELECTED_ALL_CONSENT = 'GET_UNSELECTED_ALL_CONSENT';
export const SEARCH_CONSENT = 'CONSENT_SEARCH';
export const UPDATE_CONSENT_SEARCH = 'UPDATE_CONSENT_SEARCH'
export const UPDATE_DOMAIN_SEARCH = 'UPDATE_DOMAIN_SEARCH'
export const SEARCH_DOMAIN = 'SEARCH_DOMAIN';
export const ON_HIDE_DOMAIN_LOADER = 'ON_HIDE_DOMAIN_LOADER';
export const ON_SHOW_DOMAIN_LOADER = 'ON_SHOW_DOMAIN_LOADER';
export const UPDATE_SELECTED_DOMAIN_CONSENTS = 'UPDATE_SELECTED_DOMAIN_CONSENTS';
export const UPDATE_SELECTED_DOMAIN_CONSENTS_SUCCESS = 'UPDATE_SELECTED_DOMAIN_CONSENTS_SUCCESS';
export const UPDATE_SELECTED_DOMAIN_CONSENTS_FAILURE = 'UPDATE_SELECTED_DOMAIN_CONSENTS_FAILURE';
export const GET_PREFERENCES_DATA_CONSENTS = 'GET_PREFERENCES_DATA_CONSENTS'
export const GET_PREFERENCES_DATA_CONSENTS_SUCCESS = 'GET_PREFERENCES_DATA_CONSENTS_SUCCESS'
export const GET_PREFERENCES_DATA_CONSENTS_FAILURE = 'GET_PREFERENCES_DATA_CONSENTS_FAILURE'
export const UPDATE_PREFERENCES_DATA_CONSENT = 'UPDATE_PREFERENCES_DATA_CONSENT'
export const UPDATE_PREFERENCES_DATA_CONSENT_SUCCESS = 'UPDATE_PREFERENCES_DATA_CONSENT_SUCCESS'
export const UPDATE_PREFERENCES_DATA_CONSENT_FAILURE = 'UPDATE_PREFERENCES_DATA_CONSENT_FAILURE'


export const GET_DATA_CONSENTS = 'GET_DATA_CONSENTS'
export const GET_DATA_CONSENTS_SUCCESS = 'GET_DATA_CONSENTS_SUCCESS'
export const GET_DATA_CONSENTS_FAILURE = 'GET_DATA_CONSENTS_FAILURE'
export const UPDATE_DATA_CONSENT = 'UPDATE_DATA_CONSENT'
export const UPDATE_DATA_CONSENT_SUCCESS = 'UPDATE_DATA_CONSENT_SUCCESS'
export const UPDATE_DATA_CONSENT_FAILURE = 'UPDATE_DATA_CONSENT_FAILURE'
export const FILTER_DATA_CONSENT_BY_DOMAIN = 'FILTER_DATA_CONSENT_BY_DOMAIN';


// Default Consent Actions
export const GET_DEFAULT_DOMAIN_CONSENTS = 'GET_DEFAULT_DOMAIN_CONSENTS';
export const GET_DEFAULT_DOMAIN_SUCCESS = 'GET_DEFAULT_DOMAIN_SUCCESS';
export const GET_DEFAULT_DOMAIN_FAILURE = 'GET_DEFAULT_DOMAIN_FAILURE';
export const UPDATE_DEFAULT_CONSENTS = 'UPDATE_DEFAULT_CONSENTS';
export const UPDATE_DEFAULT_CONSENTS_SUCCESS = 'UPDATE_DEFAULT_CONSENTS_SUCCESS';
export const UPDATE_DEFAULT_CONSENTS_FAILURE = 'UPDATE_DEFAULT_CONSENTS_FAILURE';
export const ON_CHECK_BOX_TOGGLE_DEFAULT_DOMAIN_CONSENT_ITEM = 'ON_CHECK_BOX_TOGGLE_DEFAULT_DOMAIN_CONSENT_ITEM';
export const SEARCH_DEFAULT_DOMAIN_CONSENT = 'SEARCH_DEFAULT_DOMAIN_CONSENT';
export const UPDATE_DEFAULT_DOMAIN_CONSENT_SEARCH = 'UPDATE_DEFAULT_DOMAIN_CONSENT_SEARCH';
export const UPDATE_FIRST_LOGIN_STATUS = 'UPDATE_FIRST_LOGIN_STATUS'
export const UPDATE_FIRST_LOGIN_STATUS_SUCCESS = 'UPDATE_FIRST_LOGIN_STATUS_SUCCESS'
export const UPDATE_FIRST_LOGIN_STATUS_FAILURE = 'UPDATE_FIRST_LOGIN_STATUS_FAILURE'
// sidebar
export const TOGGLE_MENU = 'TOGGLE_MENU';


// Auth Actions
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const CONFIRM_USER_PINCODE = 'CONFIRM_USER_PINCODE';
export const CONFIRM_USER_PINCODE_SUCCESS = 'CONFIRM_USER_PINCODE_SUCCESS';
export const CONFIRM_USER_PINCODE_FAILURE = 'CONFIRM_USER_PINCODE_FAILURE';
export const RESET_LOGIN = 'RESET_LOGIN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
export const LOGIN_SOCIAL_USER = 'LOGIN_SOCIAL_USER';
export const LOGIN_SOCIAL_USER_SUCCESS = 'LOGIN_SOCIAL_USER_SUCCESS';
export const LOGIN_SOCIAL_USER_FAILURE = 'LOGIN_SOCIAL_USER_FAILURE';

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';
export const SEND_DELETE_PROFILE_LINK = 'SEND_DELETE_PROFILE_LINK';
export const SEND_DELETE_PROFILE_LINK_SUCCESS = 'SEND_DELETE_PROFILE_LINK_SUCCESS';
export const SEND_DELETE_PROFILE_LINK_FAILURE = 'SEND_DELETE_PROFILE_LINK_FAILURE';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

// GET JWT TOKEN
export const GET_JWT_TOKEN = 'GET_JWT_TOKEN';
export const JWT_SUCCESS = 'JWT_SUCCESS';
export const JWT_FAILURE = 'JWT_FAILURE';

// GET SSO JWT TOKEN
export const GET_SSO_JWT_TOKEN = 'GET_SSO_JWT_TOKEN';
export const JWT_SSO_SUCCESS = 'JWT_SSO_SUCCESS';
export const JWT_SSO_FAILURE = 'JWT_SSO_FAILURE';

// USER PROFILE ACTIONS 
export const GET_USER_PROFILE = 'GET_USER_PROFILE' ;
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';
export const REFRESH_USER_PROFILE = 'REFRESH_USER_PROFILE' ;
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';
export const ADD_SOCIAL_LOGIN_TO_PROFILE = 'ADD_SOCIAL_LOGIN_TO_PROFILE';
export const ADD_SOCIAL_LOGIN_TO_PROFILE_SUCCESS = 'ADD_SOCIAL_LOGIN_TO_PROFILE_SUCCESS';
export const ADD_SOCIAL_LOGIN_TO_PROFILE_FAILURE = 'ADD_SOCIAL_LOGIN_TO_PROFILE_FAILURE';
export const ADD_EMAIL_TO_PROFILE = 'ADD_EMAIL_TO_PROFILE';
export const ADD_EMAIL_TO_PROFILE_SUCCESS = 'ADD_EMAIL_TO_PROFILE_SUCCESS';
export const ADD_EMAIL_TO_PROFILE_FAILURE = 'ADD_EMAIL_TO_PROFILE_FAILURE';
export const DELETE_SOCIAL_LOGIN_FROM_PROFILE = 'DELETE_SOCIAL_LOGIN_FROM_PROFILE';
export const DELETE_SOCIAL_LOGIN_FROM_PROFILE_SUCCESS = 'DELETE_SOCIAL_LOGIN_FROM_PROFILE_SUCCESS';
export const DELETE_SOCIAL_LOGIN_FROM_PROFILE_FAILURE = 'DELETE_SOCIAL_LOGIN_FROM_PROFILE_FAILURE';
export const DOWNLOAD_PROFILE = 'DOWNLOAD_PROFILE';
export const DOWNLOAD_PROFILE_SUCCESS = 'DOWNLOAD_PROFILE_SUCCESS';
export const DOWNLOAD_PROFILE_FAILURE = 'DOWNLOAD_PROFILE_FAILURE';
export const MERGE_PROFILE = 'MERGE_PROFILE';
export const MERGE_PROFILE_SUCCESS = 'MERGE_PROFILE_SUCCESS';
export const MERGE_PROFILE_FAILURE = 'MERGE_PROFILE_FAILURE';
export const GET_CURRENT_SYSTEM_ROLES = 'GET_CURRENT_SYSTEM_ROLES';
export const GET_CURRENT_SYSTEM_ROLES_SUCCESS = 'GET_CURRENT_SYSTEM_ROLES_SUCCESS';
export const GET_CURRENT_SYSTEM_ROLES_FAILURE = 'GET_CURRENT_SYSTEM_ROLES_FAILURE';
export const INVITE_FRIEND = 'INVITE_FRIEND';
export const INVITE_FRIEND_SUCCESS = 'INVITE_FRIEND_SUCCESS';
export const INVITE_FRIEND_FAILURE = 'INVITE_FRIEND_FAILURE';

//Dashboard 
//Dashboard 
export const GET_SEGMENTS = 'GET_SEGMENTS';
export const GET_SEGMENTS_SUCCESS = 'GET_SEGMENTS_SUCCESS';
export const GET_SEGMENTS_FALIURE = 'GET_SEGMENTS_FALIURE';

export const GET_GLOBAL_SEGMENTS = 'GET_GLOBAL_SEGMENTS';
export const GET_GLOBAL_SEGMENTS_SUCCESS = 'GET_GLOBAL_SEGMENTS_SUCCESS';
export const GET_GLOBAL_SEGMENTS_FALIURE = 'GET_GLOBAL_SEGMENTS_FALIURE';


export const SAVE_SEGMENTS = 'SAVE_SEGMENTS';
export const SAVE_SEGMENTS_SUCCESS = 'SAVE_SEGMENTS_SUCCESS';
export const SAVE_SEGMENTS_FALIURE = 'SAVE_SEGMENTS_FALIURE';

export const ADD_SIMILER_SEGMENTS = 'ADD_SIMILER_SEGMENTS';
export const ADD_USER_SEGMENTS = 'ADD_USER_SEGMENTS';
export const REMOVE_SEGMENTS = 'REMOVE_SEGMENTS';
export const CHANGE_WEIGHT = 'CHANGE_WEIGHT';

//AUDIT LOG 
export const GET_AUDIT_LOG = 'GET_AUDIT_LOG';
export const AUDIT_LOG_FAILURE = 'AUDIT_LOG_FAILURE';
export const AUDIT_LOG_SUCCESS = 'AUDIT_LOG_SUCCESS';
export const FILTER_AUDIT_LOG_BY_DOMAIN = 'FILTER_AUDIT_LOG_BY_DOMAIN';
export const FILTER_AUDIT_LOG_BY_CONSENT = 'FILTER_AUDIT_LOG_BY_CONSENT';
export const FILTER_AUDIT_LOG_BY_TYPE = 'FILTER_AUDIT_LOG_BY_TYPE';


//Customer Registration 
export const SAVE_CUSTOMER = 'SAVE_CUSTOMER';
export const SAVE_CUSTOMER_SUCCESS = 'SAVE_CUSTOMER_SUCCESS';
export const SAVE_CUSTOMER_FAILURE = 'SAVE_CUSTOMER_FAILURE';

//My Data Dashboard
export const GET_MY_CONSENTS = 'GET_MY_CONSENTS';
export const GET_MY_CONSENTS_FAILURE = 'GET_MY_CONSENTS_FAILURE';
export const GET_MY_CONSENTS_SUCCESS = 'GET_MY_CONSENTS_SUCCESS';
export const GET_USER_DOMAINS = 'GET_USER_DOMAINS';
export const GET_USER_DOMAINS_SUCCESS = 'GET_USER_DOMAINS_SUCCESS';
export const GET_USER_DOMAINS_FAILURE = 'GET_USER_DOMAINS_FAILURE';
export const SELECT_MY_CONSENTS_DOMAIN = 'SELECT_MY_CONSENTS_DOMAIN';
export const DOWNLOAD_CONSENTS = 'DOWNLOAD_CONSENTS';
export const DOWNLOAD_DATA_CONSENTS = 'DOWNLOAD_DATA_CONSENTS';
export const GET_OBSERVATIONS = 'GET_OBSERVATIONS';
export const GET_OBSERVATIONS_FAILURE = 'GET_OBSERVATIONS_FAILURE';
export const GET_OBSERVATIONS_SUCCESS = 'GET_OBSERVATIONS_SUCCESS';

export const DELETE_CONSENT_FOR_DOMAIN = 'DELETE_CONSENT_FOR_DOMAIN';
export const DELETE_CONSENT_FOR_DOMAIN_SUCCESS = 'DELETE_CONSENT_FOR_DOMAIN_SUCCESS';
export const DELETE_CONSENT_FOR_DOMAIN_FAILURE = 'DELETE_CONSENT_FOR_DOMAIN_FAILURE';
export const DELETE_DATA_CONSENT_FOR_DOMAIN = 'DELETE_DATA_CONSENT_FOR_DOMAIN';
export const DELETE_DATA_CONSENT_FOR_DOMAIN_SUCCESS = 'DELETE_DATA_CONSENT_FOR_DOMAIN_SUCCESS';
export const DELETE_DATA_CONSENT_FOR_DOMAIN_FAILURE = 'DELETE_DATA_CONSENT_FOR_DOMAIN_FAILURE';
export const MODIFY_DATA_CONSENT_FOR_DOMAIN = 'MODIFY_DATA_CONSENT_FOR_DOMAIN';
export const MODIFY_DATA_CONSENT_FOR_DOMAIN_SUCCESS = 'MODIFY_DATA_CONSENT_FOR_DOMAIN_SUCCESS';
export const MODIFY_DATA_CONSENT_FOR_DOMAIN_FAILURE = 'MODIFY_DATA_CONSENT_FOR_DOMAIN_FAILURE';
export const MODIFY_DATASET_CONSENT_FOR_DOMAIN="MODIFY_DATASET_CONSENT_FOR_DOMAIN";
export const SET_DOMAIN_NAME_FROM_QUERYPRAM='SET_DOMAIN_NAME_FROM_QUERYPRAM';


export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const AUTHENTICATE_USER_SUCCESS = 'AUTHENTICATE_USER_SUCCESS';
export const AUTHENTICATE_USER_FAILURE = 'AUTHENTICATE_USER_FAILURE';



//For sunburst child
export const SHOW_DOMAINS = 'SHOW_DOMAINS'
export const SHOW_SEGMENTS = 'SHOW_SEGMENTS'
//for filtering Segments while adding segments
export const FILTER_SEGMENTS="FILTER_SEGMENTS"

//for selecting and deselecting similar segments
export const SELECT_SEGEMENTS="SELECT_SEGEMENTS"
export const DESELECT_SEGEMENTS="DESELECT_SEGEMENTS"


//for changing mergeStatus

export const CHANGE_MERGE_STATUS = "CHANGE_MERGE_STATUS"

