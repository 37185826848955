/**
 * Root Sagas
 */
import { all } from 'redux-saga/effects';

// sagas
import authSagas from './Auth';
import userProfileSagas from './UserProfile';
import feedbackSagas from './Feedback';
import customerSagas from './Customer';
import myDataSagas from './Mydata';
import consentSagas from './ManageConsents'
import defaultConsentSagas from './DefaultConsents';
import auditLogSagas from './AuditLog';
import segmentDashboardSagas from './SegmentDashboard';
import magicLinkSagas from './MagicLink'


export default function* rootSaga(getState) {
    yield all([
        authSagas(),userProfileSagas(),feedbackSagas(),customerSagas(),myDataSagas(),consentSagas(),defaultConsentSagas(), auditLogSagas(),segmentDashboardSagas(),magicLinkSagas()
    ]);
}