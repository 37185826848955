import axios from 'axios'
import React, { Component } from 'react';
import { NavLink, BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import ProtectedRoute from "../../HOCs/ProtectedRoute/ProtectedRoute";
import Home from "../../pages/HomePage/HomePage";
import GetStartedPage from "../../pages/GetStartedPage/GetStartedPage"
import { NotificationContainer } from "react-notifications";
import { IntlProvider } from 'react-intl';
import { AppLocale } from '../../lang';
import { authenticateUser } from "../../actions";
import { connect } from "react-redux";
import LoginPage from "../../pages/LoginPage/LoginPage"
import AuthContext from "../../context/AuthContext";
import { Loader, Dimmer } from "semantic-ui-react";
import moment from "moment";
import 'moment/locale/fi';
import 'moment/locale/de';
import 'moment/locale/sv';
import AppConfig from '../../constants/AppConfig'
import { UserManager } from 'oidc-client';
var querystring = require('querystring');




class App extends Component {
    state={
        loading:false
    }
    
    componentDidMount() {
        const search = window.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const authorization_code = params.get('code'); 
        const authorization_session_state = params.get('session_state'); 
        if(authorization_code){
            localStorage.setItem('authorization_code', authorization_code);
        }
        if(authorization_session_state){
            localStorage.setItem('authorization_session_state', authorization_session_state);
        }
       
        
        this.props.authenticateUser()
    }

    

    
      
    render() {
        // if the appVersion is not present in localStorage then set the appVersion
        if(!localStorage.getItem('appversion')){
            localStorage.setItem('appversion', AppConfig.appVersion);
        }
        moment.locale(this.props.settings.locale.momentId)
        const loading=this.props.loading
       let roles =JSON.parse(localStorage.getItem('Roles')) 
       let isAdmin=roles?roles.includes("ADMIN"):null
       let merge = localStorage.getItem("merge")==="true"?true:false
      
       if(roles){
           
           isAdmin=roles.includes("ADMIN")||roles.includes('DOMAIN_ADMIN')
       }
       
    
        let lang = this.props.settings.locale.locale;

        const currentAppLocale = AppLocale(lang);
        return (
            <>
             <Dimmer active={loading}>
                        <Loader>Loading</Loader>
            </Dimmer>
                <Router>
                    
                    <IntlProvider
                        locale={currentAppLocale.locale}
                        messages={currentAppLocale.messages}
                    >
                        <AuthContext.Provider value={{
                            isAuth:this.props.serverState,
                            isAdmin:isAdmin
                            
                            
                        }}>
                            <NotificationContainer />
                            <Switch>
                                <ProtectedRoute isAuth={this.props.serverState} showMergeScreen={merge} ></ProtectedRoute>
                            </Switch>
                        </AuthContext.Provider>
                    </IntlProvider>
                </Router>

            </>
        );
    }

}
const mapStateToProps = ({ settings, authUser }) => {

    const { user, serverState,merge,firstLogin } = authUser;
    
    return { settings, user, serverState,merge,firstLogin };
};

export default connect(mapStateToProps, {
    // setLanguage,
    authenticateUser
})(App);

