/**
 * Auth Sagas
 */
import { all, call, fork, put, takeEvery,select,delay } from 'redux-saga/effects';
// import { push } from 'react-router-redux';

import {getAxiosRequest} from '../helpers/helpers'
import AppConfig from '../constants/AppConfig';

import {
    GET_JWT_TOKEN,
} from '../actions/types';

import {
    getJwtToken,
    tokenFailure,
    tokenSuccess,
} from '../actions';

// Get magic link user from state
export const user = (state) => state.magicLinkUser;
/**
 * Send Email address to server to get the magic link mailed.
 */

// Method to validate the magic link
const magicLinkConfirmation = async (model) => {

    return await getAxiosRequest().post(`/account/magiclinkconfirmation`, model);
}

const updateLoginTimestamp = async (userToken) => {
    return await getAxiosRequest().put(`/user/logintimestamp`, {});
}

 
/**
 * Confirm magic link
 */
function* confirmMagicLink() {

    try {
        let model = yield select(user); // <-- get the model from state
        var magicLinkModel = {
            email: model.email,
            token: model.guid
        }
        // Call API to confirm magic link
        const response  = yield call(magicLinkConfirmation,magicLinkModel);
        // If the api has confirmed the magic link it will return the response with JWT token
        if(response){
            
            localStorage.setItem('login', "true");
            yield put({
                type: 'JWT_SUCCESS',
                payload: response.data,
              });
              
              yield call(updateLoginTimestamp, response.data.jwtToken);
              yield call(delay, 2000)
              //redirect to root page.
              window.location = `${AppConfig.appUrl}`
        }else{
            //Raise Error
            localStorage.clear();
            yield put({
                type: 'JWT_FAILURE',
                payload:'Error'
              });
                  
        }
      } catch (error) {
        //Raise Error
        yield put({
          type: 'JWT_FAILURE',
          payload:'Error'
        });
      }
}

/**
 * Ger Emails
 */
export function* validateMagicLink() {
    yield takeEvery(GET_JWT_TOKEN, confirmMagicLink);
}

/**
 * Email Root Saga
 */
export default function* rootSaga() {
    yield all([
        fork(validateMagicLink)
    ]);
}