/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
import {
    GET_JWT_TOKEN,
    JWT_FAILURE,
    JWT_SUCCESS
} from '../actions/types';

/**
 * Redux Action To get the JWT Token after clicking on the magic link
 */
export const getJwtToken = (user, history) => ({
    type: GET_JWT_TOKEN,
    payload: { user, history }
});

/**
 * Redux Action JWTToken Success
 */
export const tokenSuccess = (user) => ({
    type: JWT_SUCCESS,
    payload: user
});


/**
 * Redux Action JWTToken Failure
 */
export const tokenFailure = (error) => ({
    type: JWT_FAILURE,
    payload: error
})

