import React, { Component } from "react";
import { NavLink, Route, withRouter, Redirect, Switch } from "react-router-dom";
import LoginPage from "../../pages/LoginPage/LoginPage";
import Loader from '../../components/Loader/loader'
import GetStartedPage from "../../pages/GetStartedPage/GetStartedPage";
import {
    AsyncHomepage,
    AsyncProfilePage,
    AsyncRegisterPage,
    AsyncMydataPage,
    AsyncTermsCondition,
    AsyncDomainConsent,
    AsyncMyDefaults,
    AsyncAuditLog,
    AsyncTermsConditionWithHeader,
    AsyncAddSegemnt,
    AsyncMergeDefaults
} from "../AsyncLoader/AsyncLoader";
import TermsCoditions from "../../components/Terms&Conditions/WithoutHeaderFooter/Terms&Conditions";
import MydataPage from "../../pages/MydataPage/MydataPage";
import Layout from "../Layout/Layout"
import MagicLinkConfirmation from "../../components/MagicLinkConfirmation/MagicLinkConfirmation";
import DeleteProfile from "../../components/DeleteProfile/Deleteprofile"

class ProtectedRoute extends Component {

    render() {
        
        if (this.props.location.hash=='#_=_'){
            if(this.props.isAuth){
                this.props.location.hash='#/profile'
            }
            else{
                this.props.location.hash='#/'
            }
           
        }
        if (this.props.location.pathname=="/_=_"){
            this.props.location.pathname="/login"
        }
        var routing = (
            null

        )
        
            if (this.props.isAuth) {
               
                routing = (
                    <>
                        <Layout>
                            <Switch>
                                {this.props.showMergeScreen?<Redirect exact from="/" to="/mergedefaults" />:null}
                                
                                <Route exact path="/" component={AsyncHomepage} />
                                <Route exact path="/login" component={AsyncHomepage} />
                                <Route exact path="/terms-conditions" component={AsyncTermsConditionWithHeader} />
                                <Route exact path="/profile" component={AsyncProfilePage} />
                                <Route exact path="/register-customer" component={AsyncRegisterPage} />
                                <Route exact path="/mydata" component={AsyncMydataPage} />
                                <Route exact path="/mydefaults" component={AsyncMyDefaults} />
                                <Route exact path="/auditlog" component={AsyncAuditLog} />
                                <Route exact path="/addsegments" component={AsyncAddSegemnt} />
                                <Route exact path="/app/domainconsents" component={AsyncMydataPage}/>
                                <Route exact path="/deleteprofileconfirmation" component={DeleteProfile}/>
                                <Route exact path="/mergedefaults" component={AsyncMergeDefaults}/>
                                {/* <Redirect to="/profile" /> */}
    
                            </Switch>
    
                        </Layout>
    
                    </>
    
                )
            }else{
                routing=(<>
                <Route exact path="/" component={GetStartedPage} />
                <Route exact path="/magiclinkconfirmation" component={GetStartedPage}/>
                <Route exact path="/login" component={GetStartedPage} />
                <Route exact path="/profile" component={GetStartedPage} />
                <Route exact path="/register-customer" component={GetStartedPage} />
                <Route exact path="/mydata" component={GetStartedPage} />
                <Route exact path="/mydefaults" component={AsyncMyDefaults} />
                <Route exact path="/auditlog" component={GetStartedPage} />
                <Route exact path="/addsegments" component={GetStartedPage} />
                <Route exact path="/terms-conditions" component={TermsCoditions} />
                <Route exact path="app/domainconsents" component={GetStartedPage}/>
                <Route exact path="/app/domainconsents" component={LoginPage}/>
            </>)
            }
        
        


        return (
            <>
                {routing}
            </>

        )
    }
}

export default withRouter(ProtectedRoute) 