import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import IntlMessages from "../../../../util/IntlMessages"
import AuthContext from '../../../../context/AuthContext'
import AppConfig from '../../../../constants/AppConfig'
const MenuList = (props) => {
    let width = window.screen.width
    let authContex = useContext(AuthContext)
    let adminLink = null
    let registerAsCustomer=(
        <li id="menu-index">
        <NavLink className="align-items-center color-main" to="/register-customer">
            <i className='fa fa-registered color-green-light'></i>
            <span><IntlMessages id="sidebar.customerregistration" /></span>
        </NavLink>
    </li>
    )
    const gotoAdmin = () => {
        //window.open(AppConfig.adminUrl)
        window.location = AppConfig.adminUrl;
    }
    if (authContex.isAdmin) {
        adminLink = (

            <li id="menu-index">
                <div onClick={gotoAdmin} className="align-items-center color-main">
                    <i className='fa fa-user-shield color-blue-dark'></i>
                    <span><IntlMessages id="sidebar.admin" /></span>
                </div>
            </li>

        )
        registerAsCustomer=null
    }
    let list = (
        <ul className="menu-list bottom-20">
          
            <li id="menu-index">
                <NavLink className="align-items-center color-main" to="/auditlog">
                    <i className='fa fa-history color-blue-dark'></i>
                    <span><IntlMessages id="button.consenthistory" /></span>
                </NavLink>
            </li>
            
            {/* <li onClick={props.changelang} id="menu-index">
                <div className="align-items-center color-main" >
                    <i className='fa fa-globe color-blue-light'></i>
                    <span >
                        <div style={{ paddingLeft: "32px" }}>
                            {props.currentlang}
                        </div>
                    </span>
                </div>
            </li> */}
            {registerAsCustomer}
            {/* <li id="menu-index">
                <NavLink className="align-items-center color-main" to="/terms-conditions">
                    <i className='fa fa-file-code color-green-light'></i>
                    <span><IntlMessages id="header.terms" /></span>
                </NavLink>
            </li> */}
            {adminLink}
            <li id="menu-index">
                <div onClick={props.logout} className="align-items-center color-main">
                    <i className='fa fa-sign-out-alt color-red-dark'></i>
                    <span><IntlMessages id="component.logout" /></span>
                </div>
            </li>
            
        </ul>
    )

    if (width > 750) {
        list = (
            <ul className="menu-list bottom-20">
                <li id="menu-index">
                    <NavLink className="align-items-center color-main color-main" to="/">
                        <i className='fa fa-tablet-alt color-green-dark'></i>
                        <span><IntlMessages id="sidebar.dashboard" /></span>
                    </NavLink>
                </li>
                <li id="menu-index">
                    <NavLink className="align-items-center color-main" to="/mydata">
                        <i className='fa fa-info-circle color-yellow-dark'></i>
                        <span><IntlMessages id="sidebar.datasetconsents" /></span>
                    </NavLink>
                </li>

                <li id="menu-index">
                    <NavLink className="align-items-center color-main" to="/mydefaults">
                        <i className='fa fa-pen-square color-brown-light'></i>
                        <span><IntlMessages id="sidebar.defaultconsents" /></span>
                    </NavLink>
                </li>
                <li id="menu-index">
                    <NavLink className="align-items-center color-main" to="/profile">
                        <i className='fa fa-id-card color-green-light'></i>
                        <span><IntlMessages id="sidebar.profile" /></span>
                    </NavLink>
                </li>
                <li id="menu-index">
                    <NavLink className="align-items-center color-main" to="/auditlog">
                        <i className='fa fa-history color-blue-dark'></i>
                        <span><IntlMessages id="button.consenthistory" /></span>
                    </NavLink>
                </li>
                <li onClick={props.feedback} id="menu-index">
                    <div className="align-items-center color-main" >
                        <i className='fa fa-comment-alt color-blue-dark'></i>
                        <span><IntlMessages id="component.feedback" /></span>
                    </div>
                </li>
                {/* <li onClick={props.changelang} id="menu-index">
                    <div className="align-items-center color-main" >
                        <i className='fa fa-globe color-blue-light'></i>
                        <span >
                            <div style={{ paddingLeft: "32px" }}>
                                {props.currentlang}
                            </div>
                        </span>
                    </div>
                </li> */}
                {registerAsCustomer}
                {/* <li id="menu-index">
                    <NavLink className="align-items-center color-main" to="/terms-conditions">
                        <i className='fa fa-file-code color-green-light'></i>
                        <span><IntlMessages id="header.terms" /></span>
                    </NavLink>
                </li> */}
                {adminLink}
                <li id="menu-index">
                    <div onClick={props.logout} className="align-items-center color-main">
                        <i className='fa fa-sign-out-alt color-red-dark'></i>
                        <span><IntlMessages id="component.logout" /></span>
                    </div>
                </li>
                
            </ul>
        )
    }
    return (
        <>
            <div className="menu-title">
                <h1>Gravito</h1>
                {/* <div onClick={props.toggle} className="menu-hide">
                    <i className="fa fa-times"></i>
                </div> */}
            </div>
            <div className="menu-page" onClick={props.toggle}>
                {list}
            </div>
        </>
    )
}

export default MenuList