import React,{useContext} from "react";
import { Card } from "semantic-ui-react";
import authContext from "../../../context/AuthContext"
const TermsAndConditions = (props) => {
    const isAuth=useContext(authContext)
    const goBack=()=>{
        if(isAuth.isAuth){
            props.history.push('/dashboard')
        }
        else{
            props.history.push('/login')
        }
        
    }
    return (
        <>

            <div className="terms-conditions-header">
                <i onClick={goBack} style={{ fontSize: "20px" }} className="fas fa-arrow-left"></i>
                <h4 style={{ marginTop: "0px", marginLeft: "2vw" }} className="ultrabold">GRAVITO SERVICE TERMS AND CONDITIONS (for beta version) </h4>
            </div>
            <Card className="card-custom">
                <Card.Description>
                    <p className="regular font-14 bottom-10">These are the standard terms, conditions and pricing that apply to consumers using Gravito service, offered to you as beta version.</p>
                </Card.Description>
            </Card>
            <Card className="card-custom">
                <h3 className="regularbold">
                    Terms of Gravito use
                </h3>
                <p className="regular font-14 bottom-10">
                    These terms explain the use of Gravito service <a href="https://account.gravito.net">account.gravito.net</a> through any website for a registered user. By using Gravito service (account.gravito.net)  (“Gravito Service”), you accept these terms of use.
                </p>
                <h3 className="regularbold">
                    INFORMATION ABOUT US
                </h3>
                <p className="regular font-14 bottom-10">
                    <strong>Gravito</strong> Service is operated by Gravito Limited ("We").  We are registered in Finland under company number 2891268-5 and have our registered office at Karaportti 2, 02620 Espoo, Finland.
                </p>
                <h3 className="regularbold">ACCESSING OUR BETA SERVICE</h3>
                <p className="regular font-14 bottom-10">We do our best to keep the Gravito Service up and running but sometimes things happen that are out of our control. Please note that Gravito Service is not yet a final version, but still a beta version, and for those unexpected instances we cannot take liability. You are responsible for actions taking place as a result of accessing Gravito Service via your password or identification code.</p>
                <h3 className="regularbold">INTELLECTUAL PROPERTY RIGHTS</h3>
                <p className="regular font-14 bottom-10">We own the licenses of all intellectual property rights on Gravito Service, and the material that may be published on it, including the service source code.</p>
                <h3 className="regularbold">OUR LIMITED LIABILITY</h3>
                <p className="regular font-14 bottom-10">We are closely monitoring Gravito Service to ensure security and availability of Gravito Service. However, accessing Gravito Service and any action you carry out based on data or information you obtain from or via Gravito Service is carried out entirely at your own risk.</p>
                <h3 className="regularbold">UPLOADING INFORMATION TO OUR SITE</h3>
                <p className="regular font-14 bottom-10">You will be responsible and liable to any third party, for the content, sufficient permissions or accuracy of any materials posted by you.</p>
                <h3 className="regularbold">INFORMATION ABOUT YOU AND YOUR VISITS TO OUR SITE</h3>
                <p className="regular font-14 bottom-10">We process information about you based on your consent. All your personal and behavioral data reside in the Gravito service cloud within EU region and it is managed and owned by you. Gravito will process your data according to mandatory legal requirements, as explained in <strong>Gravito Privacy Notice</strong> below.</p>
                <h3 className="regularbold">VARIATIONS</h3>
                <p className="regular font-14 bottom-10">We may revise these terms of use by amending this page and notifying you for your approval for any material changes.</p>
            </Card>
            <Card className="card-custom">
                <h3 className="regularbold">
                    Gravito Privacy Notice
                </h3>
                <ol>
                    <li className="bold">Types of data we collect: Your
					<ol>
                            <li>User Name</li>
                            <li>Mail address</li>
                            <li>Phone number</li>
                            <li>Traditional mail address</li>
                            <li>Any other information you choose to share with us</li>
                        </ol>
                    </li>
                    <li className="bold">How we use your data to
					<ol>
                            <li>keep Gravito running</li>
                            <li>help us improve Gravito</li>
                            <li>give personalized customer support</li>
                            <li>send you marketing messages - only if you tell us to</li>
                            <li>share your data with other parties within gravito network if you choose to do so</li>
                        </ol>
                    </li>
                    <li className="bold">Third parties who process your data
					<ol>
                            <li>Infrastructure: Azure (Not Optional)</li>
                            <li>Optional 3rd parties:
                                    <ul className="sub-order">
                                    <li>External login options: Google, Facebook</li>
                                    <li>Other: You choose and have full control,  who you wish to share your data with, by giving specific access to the chosen party you choose via Gravito. You can revoke and change these permissions at any time.</li>
                                </ul>
                            </li>
                        </ol>
                    </li>
                    <li className="bold">We use cookies
					<ol>
                            <li>We use only necessary cookies to run and improve our service</li>
                            <li>You can turn off cookies and we will not be able to recognize you anymore</li>
                        </ol>
                    </li>
                </ol>
            </Card>
            <Card className="card-custom">
                <h3 className="regularbold">YOUR CONCERNS</h3>
                <p className="regular font-14 bottom-10">If you have any concerns about material which appears on Gravito Service, please email  <a href="mailto:support@gravito.net">support@gravito.net</a></p>
            </Card>


        </>
    )
}

export default TermsAndConditions