import React, { Component } from "react";
import FullScreenSlider from "../../components/FullScreenSlider/FullScreenSlider";
import { NavLink, withRouter } from 'react-router-dom'
import PackChart from '../HomePage/HomePageComponents/ChartPack'
import TreeChart from "./HomePageComponents/tree";
import { connect } from 'react-redux';
import { Dimmer, Loader, Button } from 'semantic-ui-react';
import IntlMessages from '../../util/IntlMessages';
import {
    getSegment,
    getGlobalSegment,
    changeWeight,
    removeSegment,
    addSimilerSegment,
    addUserSegment,
    saveSegment,
    showdomains,
    showsegments
} from '../../actions';
import * as _ from 'lodash';


class Home extends Component {
    state = {
        showdomains: false,
        showconcent: false,
        data: {
            title: "root",
            label: "root",
            dontRotateLabel: true

        }
    }






    componentDidMount() {
        window.scrollTo(0, 0)
        this
            .props
            .getSegment();
        

            setTimeout(()=>{
                this
                .props
                .getGlobalSegment();
                
            },2000)    
       
    }
    takeToAddSegment = () => {
        this.props.history.push("/addsegments")
    }

    render() {
       
        return (
            <>
                <div  className="profile-div">
                    <Dimmer active={this.props.loading||!this.props.plotdata}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <div style={{minHeight:"90vh"}} className=" margin-auto card-custom">
                        <span className="select-domain">
                            <IntlMessages id="sidebar.dashboard" />

                        </span>
                       
                        {
                            this.props.plotdata?this.props.plotdata.children.length!=0?
                            <div className="chart-div">
                             {/* <PackChart data={this.props.plotdata}></PackChart> */}
                             <TreeChart data={this.props.plotdata}/>
                        </div>:
                        <p>
                            <IntlMessages id="dashboard.info" />
                        </p>:null

                        }
                        
                    </div>
                    <Button className="floating-btn shadow-medium color-white bg-theme-color" circular icon="plus" onClick={this.takeToAddSegment} ></Button>

                </div> 
                {/* <div className="cover-overlay overlay bg-black opacity-90"></div>
                {/* <div className="dash-div">
                    <div className="page-content page-content-full">
                        <div className="cover-item bg-img" >
                            <div className="cover-content cover-content-center bottom-50">

                                <div>
                                {this.props.plotdata?<PackChart data={this.props.plotdata}></PackChart>:null}
                                </div>

                            </div>
                            <div className="cover-overlay overlay overlay-dark"></div>
                        </div>


                    </div>
                </div> */}


            </>
        )
    }
}


const mapStateToProps = ({ userSegments }) => {
    
    return userSegments;
}

export default connect(mapStateToProps, {
    getSegment,
    getGlobalSegment,
    changeWeight,
    removeSegment,
    addSimilerSegment,
    addUserSegment,
    saveSegment,
    showdomains,
    showsegments
})(withRouter(Home));