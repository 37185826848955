import React from 'react';
import './App.css';
import { configureStore } from "./store/index";
import { Provider } from "react-redux";
import MainApp from "../src/containers/MainApp/MainApp"
function  App(){
  return (
    <>
      <Provider store={configureStore()}>
        <MainApp/>
      </Provider>
    </>
  );
}
export default App

