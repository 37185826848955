import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment';
import * as $ from "jquery";
import { Button,Dimmer,Loader } from "semantic-ui-react";
import MenuList from "../MenuList/Menulist";
import SettingList from "../SettingList/SettingList"
import { withRouter, NavLink } from "react-router-dom";
import AppConfig from '../../../../constants/AppConfig';
import { setLanguage, logOff, sendFeedback, inviteFriend } from "../../../../actions"
import FeedbackAlert from "../../../../components/FeedbackAlert/FeedbackAlert";
import InviteFriend from "../../../../components/InviteFriend/InviteFriend";
import { NotificationManager } from 'react-notifications';
import IntlMessages from "../../../../util/IntlMessages"




class Header extends Component {
    state = {
        popupMenuVissible: false,
        popupSettingVissible: false,
        popupFeedbackVissible: false,
        popupInviteFriendVissible: false

    }
    bottomMenuToggle = () => {

        if (this.state.popupSettingVissible) {
            this.bottomSettingToggle()
        }
        if (this.state.popupFeedbackVissible) {
            this.bottomFeedbackToggle()
        }

        this.setState((prevstate) => {

            return (
                {
                    popupMenuVissible: !prevstate.popupMenuVissible
                }
            )
        })
    }
    sendFeedback = (message) => {
        if (message == null || message == "") {
            NotificationManager.error('cannot send an empty message ');
        } else {
            this.props.sendFeedback(message)
            this.bottomFeedbackToggle()
        }

    }
    bottomSettingToggle = () => {


        if (this.state.popupFeedbackVissible) {
            this.bottomFeedbackToggle()
        }
        this.setState((prevstate) => {

            return (
                {
                    popupSettingVissible: !prevstate.popupSettingVissible,

                }
            )
        })
    }
    goToGetStarted = () => {

        window.location = `${AppConfig.appUrl}`
    }
    logOut = () => {

        this.props.logOff()

    }
    onChangeLanguage = (lang) => {

        this.props.setLanguage(lang);
        this.bottomSettingToggle()
    }
    invite = (email) => {
        this.props.inviteFriend(email)
        this.inviteFriendToggle()

    }
    closeModal = () => {
        this.setState({
            popupMenuVissible: false,
            popupSettingVissible: false,
            popupFeedbackVissible: false,
            popupInviteFriendVissible: false
        })
    }
    bottomFeedbackToggle = () => {
        if (this.state.popupSettingVissible) {
            this.bottomSettingToggle()
        }

        this.setState((prevstate) => {

            return (
                {
                    popupFeedbackVissible: !prevstate.popupFeedbackVissible
                }
            )
        })
    }
    inviteFriendToggle = () => {

        this.setState((prevstate) => {

            return (
                {
                    popupInviteFriendVissible: !prevstate.popupInviteFriendVissible
                }
            )
        })
    }

    render() {
        const { languages, locale, rest, loading } = this.props

        let bottomMenuClasses = ""
        let bottomSettingClasses = ""
        let bottomFeedbackClasses = ""
        let inviteFriendClasses = ""
        let hider = ""
        if (this.state.popupMenuVissible) {
            bottomMenuClasses = "menu-from-bottom bottom-menu-active";
            hider = "new-hider"
        }
        else {
            bottomMenuClasses = "menu-from-bottom"

        }
        if (this.state.popupSettingVissible) {
            bottomSettingClasses = "setting-from-bottom bottom-setting-active";
            hider = "new-hider"
        }
        else {
            bottomSettingClasses = "setting-from-bottom"
        }
        if (this.state.popupFeedbackVissible) {
            bottomFeedbackClasses = "feedback-from-bottom bottom-feedback-active";
            hider = "new-hider"
        }
        else {
            bottomFeedbackClasses = "feedback-from-bottom"

        }
        if (this.state.popupInviteFriendVissible) {
            inviteFriendClasses = "feedback-from-bottom bottom-feedback-active";
            hider = "new-hider"
        }
        else {
            inviteFriendClasses = "feedback-from-bottom"

        }

        return (
            <>
                <Dimmer active={loading}>
                    <Loader>Loading</Loader>
                </Dimmer>
                <div id="page-transitions" className="color-main page-build light-skin highlight-blue">
                    <div onClick={this.closeModal} className={hider}></div>
                    <div className="header header-scroll-effect">
                        <div className="header-line-2 header-scroll-effect ">
                            <a href="javascript:;" className="date-header theme-color"><IntlMessages id="header.title" /></a>
                            <a href="javascript:;"  style={{ top: "4px" }} className="header-title">Gravito</a>
                            <button onClick={this.bottomMenuToggle} className="header-icon header-icon-1 transparent "><i className="fa fa-bars "></i></button>
                            <button onClick={this.bottomSettingToggle} className="header-icon header-icon-2 transparent"><i className="fa fa-globe"></i></button>
                            <button onClick={this.inviteFriendToggle} className="header-icon header-icon-3 transparent"><i className="fa fa-user-plus"></i></button>


                        </div>
                        <div className="header-line-1 header-logo-app">
                            <a href="#" onClick={this.goToGetStarted} className="back-button  header-logo-title">Gravito</a>
                            <button onClick={this.bottomMenuToggle} className="header-icon header-icon-4 transparent"><i className="app-contrast-color fa fa-bars"></i></button>
                            <button style={{ right: "38px" }} onClick={this.bottomSettingToggle} className="header-icon header-icon-2 transparent"><i className="app-contrast-color fa fa-globe"></i></button>
                            <button style={{ right: "73px" }} onClick={this.inviteFriendToggle} className="header-icon header-icon-3 transparent"><i className="app-contrast-color fa fa-user-plus"></i></button>
                        </div>

                    </div>
                    <div className={bottomMenuClasses}>
                        <MenuList logout={this.logOut}
                            toggle={this.bottomMenuToggle}
                            currentlang={locale.name}
                            changelang={this.bottomSettingToggle}
                            feedback={this.bottomFeedbackToggle} />
                    </div>
                    <div className={bottomSettingClasses}>
                        <SettingList
                            toggle={this.bottomSettingToggle}
                            languageList={languages}
                            onChange={this.onChangeLanguage}
                        />
                    </div>
                    <div className={bottomFeedbackClasses}>
                        <FeedbackAlert toggle={this.bottomFeedbackToggle} SendFeedback={this.sendFeedback}></FeedbackAlert>
                    </div>
                    <div className={inviteFriendClasses}>
                        <InviteFriend toggle={this.inviteFriendToggle} invite={this.invite}></InviteFriend>
                    </div>
                </div>
            </>
        )
    }
}


const mapStateToProps = ({ settings, userProfile }) => {

    return { ...settings, ...userProfile }
};

export default connect(mapStateToProps, {
    setLanguage,
    logOff,
    sendFeedback, inviteFriend

})(withRouter(Header));