/**
 * Todo Sagas
 */
import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
// app config
import AppConfig from '../constants/AppConfig';
import { getAxiosRequest } from "../helpers/helpers"



import {
    getDomainSuccess,
    getDomainFailure,
    updateSelectedDomainSuccess,
    updateSelectedDomainFailure,
    getPreferencesDataConsentsSuccess,
    getPreferencesDataConsentsFailure,
    updatePreferencesDataConsentSuccess,
    updatePreferencesDataConsentFailure,
    getObservationsSuccess,
    getObservationsFailure

} from '../actions';



/**
 * Send Domain Request To Servercons
 * The url will be changed to api url later
 */



// Get manage consents state
export const domainConsent = (state) => state.manageConsentsReducer;

// Get consents from Server
const UserConsents = async (model) => {
    return await getAxiosRequest().get('/consent');
}

// Get data consents from Server
const UserDataConsents = async (model) => {

    // return await getAxiosRequest().get(`/consent/data/domain?domain=${model.selectedDomain}` );
    return await getAxiosRequest().get(`/consent/all?domain=${model.selectedDomain}`);
}

// Get data consents from Server
const Observations = async (domain) => {
    return await getAxiosRequest().get(`/user/op?domain=` + domain);
}


// Method to update the consents for the selected domain
const updateConsents = async (model) => {
    return await getAxiosRequest().post('/consent', model);
}


// Method to update the data consent for the selected domain
const updateDataConsent = async (model) => {
    return await getAxiosRequest().put('/consent/data', model);
}

/**
 * Get Domains and Consents
 */
function* getDomainsFromServer() {
    try {
        const response = yield call(UserConsents);
        yield put(getDomainSuccess(response));
    } catch (error) {
        if (error.response && error.response.status === 401) {
            return null
        }


        yield put(getDomainFailure(error));



    }
}

/**
 * Get Data Consents from Server
 */
function* getDataConsents() {

    let model = yield select(domainConsent)
    try {
        const response = yield call(UserDataConsents, model);
        yield put(getPreferencesDataConsentsSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(getPreferencesDataConsentsFailure(error));
    }
}

/**
 * Get Observations from observed profile for the domain
 */
function* getObservations() {
    try {
        let model = yield select(domainConsent); // <-- get the model

        const response = yield call(Observations, model.selectedDomain);
        yield put(getObservationsSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(getObservationsFailure(error));
    }
}


/**
 * Update Selected Domain Consents 
 */
function* updateDomainConsents() {

    try {
        let model = yield select(domainConsent); // <-- get the model
        var consentSet = [];

        model.selectedConsents.forEach(element => {
            var consentUpdateModel = {
                domain: model.selectedDomain,
                name: element.name,
                value: element.value
            }
            consentSet.push(consentUpdateModel);
        });


        const response = yield call(updateConsents, consentSet);

        yield put(updateSelectedDomainSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(updateSelectedDomainFailure(error));
    }
}

/**
 * Update Selected Domain Consents 
 */
function* updateDataConsentForDomain() {
    try {
        let model = yield select(domainConsent); // <-- get the model


        const response = yield call(updateDataConsent, model.selectedDataConsent);
        yield put(updatePreferencesDataConsentSuccess(response));
        const refreshResponse = yield call(UserDataConsents);
        yield put(getPreferencesDataConsentsSuccess(refreshResponse));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(updatePreferencesDataConsentFailure(error));
    }
}



export const selectedDomainSagas = [
    takeEvery('GET_DOMAINS', getDomainsFromServer),
    takeEvery("UPDATE_SELECTED_DOMAIN_CONSENTS", updateDomainConsents),
    takeEvery('ON_SELECT_DOMAIN', getDataConsents),
    takeEvery("UPDATE_PREFERENCES_DATA_CONSENT", updateDataConsentForDomain),
    takeEvery('GET_OBSERVATIONS', getObservations)
]



/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...selectedDomainSagas,
    ])
}
