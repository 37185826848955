import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import * as d3 from 'd3';

class PackChart extends Component {
    
    // "hsl(152,80%,80%)", "hsl(228,30%,40%)"]
    componentDidMount = () => {
        
        var svg = d3.select(this.refs.container),
            margin = 20,
            diameter = +svg.attr("width"),
            g = svg.append("g").attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")");
        var color = d3.scaleLinear()
            .domain([-1, 5])
            .range(["#FFAD36","#C70039"])
            .interpolate(d3.interpolateHcl);
        var pack = d3.pack()
            .size([(diameter - margin), (diameter - margin)])
            .padding(2);
        

        var rootNode = d3.hierarchy(this.props.data)

        rootNode.sum(function (d) {
            return d.size;
        }).sort(function (a, b) { return b.value - a.value; });
        pack(rootNode);
        var focus = rootNode,
            nodes = rootNode.descendants(),
            view;
        var circle = g.selectAll('circle')
            .data(rootNode.descendants())
            .enter()
            .append('circle')
            .attr("class", function (d) { return d.parent ? d.children ? "node" : "node node--leaf" : "node node--root"; })
            .style("fill", function (d) { return d.children ? color(d.depth) : null; })
            .on("click", function (d) {
                if (focus !== d) {
                    
                    
                    if(d.depth==2){
                        if(focus!==d.parent){
                            zoom(d.parent)
                        }
                        d3.event.stopPropagation();
                        return null
                    }
                    zoom(d)
                        d3.event.stopPropagation();
                    
                   
                    
                }

            });

        var text = g.selectAll("text")
            .data(rootNode.descendants())
            .enter().append("text")
            .attr("class", "label")
            .style("fill-opacity", function (d) { return d.parent === rootNode ? 1 : 0; })
            .style("display", function (d) { return d.parent === rootNode ? "inline" : "none"; })
            .text(function (d) { return d.data.name; })

        var node = g.selectAll("circle,text");

        svg
            .style("background", "transparent")
            .on("click", function () { zoom(rootNode); });

        zoomTo([rootNode.x, rootNode.y, rootNode.r * 2 + margin]);

        function zoom(d) {
            var focus0 = focus; focus = d;

            var transition = d3.transition()
                .duration(d3.event.altKey ? 7500 : 750)
                .tween("zoom", function (d) {
                    var i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
                    return function (t) { zoomTo(i(t)); };
                });

            transition.selectAll("text")
                .filter(function (d) { return d.parent === focus || this.style.display === "inline"; })
                .style("fill-opacity", function (d) { return d.parent === focus ? 1 : 0; })
                .on("start", function (d) { if (d.parent === focus) this.style.display = "inline"; })
                .on("end", function (d) { if (d.parent !== focus) this.style.display = "none"; });
        }

        function zoomTo(v) {
            var k = diameter / v[2]; view = v;
            node.attr("transform", function (d) { return "translate(" + (d.x - v[0]) * k + "," + (d.y - v[1]) * k + ")"; });
            circle.attr("r", function (d) { return d.r * k; });
        }



        // var text = g.selectAll("text")
        //     .data(nodes)
        //     .enter().append("text")
        //     .attr("class", "label")
        //     .style("fill-opacity", function (d) { return d.parent === root ? 1 : 0; })
        //     .style("display", function (d) { return d.parent === root ? "inline" : "none"; })
        //     .text(function (d) { return d.data.name; });

        // var node = g.selectAll("circle,text");

        // svg
        //     .style("background", color(-1))





    }
    componentDidUpdate = (prevProps) => {
    
        
            d3.select(this.refs.container).selectAll("*").remove();
            var svg = d3.select(this.refs.container),
            margin = 20,
            diameter = +svg.attr("width"),
            g = svg.append("g").attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")");
        var color = d3.scaleLinear()
            .domain([-1, 5])
            .range(["#FFAD36","#C70039"])
            .interpolate(d3.interpolateHcl);
        var pack = d3.pack()
            .size([(diameter - margin), (diameter - margin)])
            .padding(2);
        

        var rootNode = d3.hierarchy(this.props.data)

        rootNode.sum(function (d) {
            return d.size;
        }).sort(function (a, b) { return b.value - a.value; });
        pack(rootNode);
        var focus = rootNode,
            nodes = rootNode.descendants(),
            view;
        var circle = g.selectAll('circle')
            .data(rootNode.descendants())
            .enter()
            .append('circle')
            .attr("class", function (d) { return d.parent ? d.children ? "node" : "node node--leaf" : "node node--root"; })
            .style("fill", function (d) { return d.children ? color(d.depth) : null; })
            .on("click", function (d) {
                if (focus !== d) {
                    if(d.depth==2){
                        if(focus!==d.parent){
                            zoom(d.parent)
                        }
                        d3.event.stopPropagation();
                        return null
                    }
                    zoom(d)
                        d3.event.stopPropagation();
                    
                   
                    
                }

            });

        var text = g.selectAll("text")
            .data(rootNode.descendants())
            .enter().append("text")
            .attr("class", "label")
            .style("fill-opacity", function (d) { return d.parent === rootNode ? 1 : 0; })
            .style("display", function (d) { return d.parent === rootNode ? "inline" : "none"; })
            .text(function (d) { return d.data.name; })

        var node = g.selectAll("circle,text");

        svg
            .style("background", "transparent")
            .on("click", function () { zoom(rootNode); });

        zoomTo([rootNode.x, rootNode.y, rootNode.r * 2 + margin]);

        function zoom(d) {
            var focus0 = focus; focus = d;

            var transition = d3.transition()
                .duration(d3.event.altKey ? 7500 : 750)
                .tween("zoom", function (d) {
                    var i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
                    return function (t) { zoomTo(i(t)); };
                });

            transition.selectAll("text")
                .filter(function (d) { return d.parent === focus || this.style.display === "inline"; })
                .style("fill-opacity", function (d) { return d.parent === focus ? 1 : 0; })
                .on("start", function (d) { if (d.parent === focus) this.style.display = "inline"; })
                .on("end", function (d) { if (d.parent !== focus) this.style.display = "none"; });
        }

        function zoomTo(v) {
            var k = diameter / v[2]; view = v;
            node.attr("transform", function (d) { return "translate(" + (d.x - v[0]) * k + "," + (d.y - v[1]) * k + ")"; });
            circle.attr("r", function (d) { return d.r * k; });
        }


        
    }




    render() {
        const [height,width]=[ window.screen.height,window.screen.width]
        let w=340
        if (width>750){
            w=650
        }
        if(width < 350){
            w=280
        }
        return (
            <>
                <svg  width={w} height={w} ref='container'></svg>
            </>
        )
    }
}

export default PackChart