import React,{useState,useEffect} from "react";
import { TextArea,Button } from "semantic-ui-react"
import IntlMessages from "../../util/IntlMessages";
import { NotificationManager } from 'react-notifications';
import { injectIntl, intlShape } from 'react-intl';

const InviteFriend =(props)=>{
    const placeholder = props.intl.formatMessage({id: 'welcome.friendemail'});
    const [email,changemail]=useState({
        friendEmail:"",
        
        
    })
   function checkValidity (email){
    var re = /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/igm;
    if (re.test(email)) {
        return true

    } else {
        return false
    }
   }
     

    function onEmailChange(event) {
       
        
        changemail({friendEmail: event.target.value});
       
    }
    function invite() {
        
        if (email.friendEmail !== '' && checkValidity(email.friendEmail)) {
            props.invite(email.friendEmail)
        } else {
            NotificationManager.error('Please enter a valid email address');
        }

    }

return(
    <>
        <div style={{ padding: "8px" }}>
                <h3 className="ultrabold color-main"><IntlMessages id="welcome.invitefriend"/></h3>
                <div onClick={props.toggle} className="feedback-hide">
                    <i className="fa fa-times"></i>
                </div>
                <div className="deco-4"></div>
                <div className="input-simple-1 has-icon input-green">
                            
                            <i className="fa fa-envelope"></i>
                            <input type="email" 
                            autoComplete="off"
                            placeholder={placeholder}
                            value={email.friendEmail}
                            onChange={(e) => onEmailChange(e)}
                            required/>
                                
                </div>
                
                <button style={{marginTop:"10px"}}  className="button bg-theme-color  button-round right-float"
                onClick={invite}
                >
                <i style={{marginRight:"10px"}} className="fa fa-paper-plane"></i>
                <IntlMessages id="welcome.invitefriend"/></button>
            </div>
    </>
)
}

export default injectIntl(InviteFriend) 