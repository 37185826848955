/**
 * User Profile Reducer
 */
/* eslint-disable */

import * as _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import fileDownload from 'react-file-download';
import React  from 'react';
import IntlMessages from '../util/IntlMessages';

import AppConfig from '../constants/AppConfig';

// action types
import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    REFRESH_USER_PROFILE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAILURE,
    ADD_SOCIAL_LOGIN_TO_PROFILE,
    ADD_SOCIAL_LOGIN_TO_PROFILE_SUCCESS,
    ADD_SOCIAL_LOGIN_TO_PROFILE_FAILURE,
    DELETE_SOCIAL_LOGIN_FROM_PROFILE,
    DELETE_SOCIAL_LOGIN_FROM_PROFILE_SUCCESS,
    DELETE_SOCIAL_LOGIN_FROM_PROFILE_FAILURE,
    DOWNLOAD_PROFILE,
    DOWNLOAD_PROFILE_SUCCESS,
    DOWNLOAD_PROFILE_FAILURE, 
    MERGE_PROFILE, 
    MERGE_PROFILE_SUCCESS,
    MERGE_PROFILE_FAILURE,
    GET_CURRENT_SYSTEM_ROLES,
    GET_CURRENT_SYSTEM_ROLES_SUCCESS,
    GET_CURRENT_SYSTEM_ROLES_FAILURE,
    INVITE_FRIEND,
    INVITE_FRIEND_SUCCESS,
    INVITE_FRIEND_FAILURE

} from '../actions/types';

// initial state
const INIT_STATE = {
    userProfile:null,
    socialLogin:null,
    loginToDelete:null,
    loading:false,
    observedProfile:null, 
    currentRoles:null,
    friendEmail:null
};
    const Message = ({ id }) => (
        <IntlMessages id={id} />
    );

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get profile of logged in user
        case GET_USER_PROFILE:
            return { ...state, loading:true };
        // Success in getting profile, set the response in the state...
        case GET_USER_PROFILE_SUCCESS:
            return { ...state, loading:false, userProfile:action.payload.data};           
        // failure in fetching the profile    
        case GET_USER_PROFILE_FAILURE:
            NotificationManager.error(Message({id:"profile.loadFailure"}));
            return { ...state, loading: false };             
        case REFRESH_USER_PROFILE:
            return { ...state, userProfile:action.payload.data, loading: false }; 
        // Update User Profile
        case UPDATE_USER_PROFILE:
            return { ...state, loading:true, userProfile:state.userProfile };
        // success in updating profile
        case UPDATE_USER_PROFILE_SUCCESS: 
            NotificationManager.success(Message({id:"profile.updateSuccess"}));
            return { ...state, loading:false };
        //failure to update the profile           
        case UPDATE_USER_PROFILE_FAILURE:
            NotificationManager.error(Message({id:"profile.updateFailure"}));
            return { ...state, loading: false };             
        // Add Social Login to profile
        case ADD_SOCIAL_LOGIN_TO_PROFILE:
        if (action.payload === '') {
            return { ...state };
        } else {
            return { ...state, loading: true, socialLogin:action.payload };
        }
        // USER logs in successfully with the social login 
        case ADD_SOCIAL_LOGIN_TO_PROFILE_SUCCESS:
            NotificationManager.success(Message({id:"profile.addLoginSuccess"}));
            return { ...state, loading: false, socialLogin:null};
        // Failure to login with social profile      
        case ADD_SOCIAL_LOGIN_TO_PROFILE_FAILURE:
            NotificationManager.error(Message({id:"profile.addLoginFailure"}));
            return { ...state, loading: false, socialLogin:null };
        // Delete Social Login to profile
        case DELETE_SOCIAL_LOGIN_FROM_PROFILE:
        if (action.payload === '') {
            return { ...state };
        } else {
            return { ...state, loading: true, loginToDelete:action.payload };
        }
        // USER logs in successfully with the social login 
        case DELETE_SOCIAL_LOGIN_FROM_PROFILE_SUCCESS:
            NotificationManager.success(Message({id:"profile.removeLoginSuccess"}));
            return { ...state, loading: false, loginToDelete:null};
        // Failure to login with social profile      
        case DELETE_SOCIAL_LOGIN_FROM_PROFILE_FAILURE:
            NotificationManager.error(Message({id:"profile.removeLoginFailure"}));
            return { ...state, loading: false, loginToDelete:null };

        case DOWNLOAD_PROFILE:
            if (action.payload === '') {
                return { ...state };
            } else {
                return { ...state, loading: true };
            }
            // Received profile data from api 
            case DOWNLOAD_PROFILE_SUCCESS:
                fileDownload(JSON.stringify(action.payload.data.result), 'GravitoProfile.json');
                return { ...state, loading: false};
            // Failure to download user profile
            case DOWNLOAD_PROFILE_FAILURE:
                NotificationManager.error(Message({id:"profile.downloadFailure"}));
                return { ...state, loading: false };

            case MERGE_PROFILE:
                if (action.payload === '') {
                    return { ...state };
                } else {
                    return { ...state, loading: true, observedProfile:action.payload };
                }
            // Received profile data from api 
            case MERGE_PROFILE_SUCCESS:

                NotificationManager.success(Message({id:"mergeProfile.success"}));
                

                
                return { ...state, loading: false, observedProfile:null};
            // Failure to merge user profile
            case MERGE_PROFILE_FAILURE:
                NotificationManager.error(Message({id:"mergeProfile.failure"}));
                return { ...state, loading: false };

            // get current roles of logged in user
        case GET_CURRENT_SYSTEM_ROLES:
        return { ...state, loading:true };
        // Success in getting current roles, set the response in the state...
        case GET_CURRENT_SYSTEM_ROLES_SUCCESS:
            var loginCookie = JSON.parse(localStorage.getItem('CXGravitoUid'));
            if(!_.isEqual(loginCookie.roles.sort(), action.payload.data.sort())){
                NotificationManager.success(Message({id:"user.profileupdate"}));
    
                setTimeout(() => {
                     sessionStorage.clear();
                     localStorage.clear();
                     window.location = `${AppConfig.appUrl}`
                   }, 3000);
            }        
            return { ...state, loading:false, currentRoles:action.payload.data};           
        // failure in fetching the current roles    
        case GET_CURRENT_SYSTEM_ROLES_FAILURE:
            return { ...state, loading: false, currentRoles:null };
        case INVITE_FRIEND:
            return { ...state, loading: true, friendEmail:action.payload };
        case INVITE_FRIEND_SUCCESS:
            NotificationManager.success(Message({id:"user.invitefriend"}));
            return { ...state, loading: false, friendEmail:null };
        case INVITE_FRIEND_FAILURE:      
            NotificationManager.error(Message({id:"user.inviteerror"}));
            return { ...state, loading: false, friendEmail:null };
        default: return { ...state };

    }
}
