/**
 * Feedback Sagas
 */
import { all, call, fork, put, takeEvery,select } from 'redux-saga/effects';
import * as _ from 'lodash'
import {getAxiosRequest} from '../helpers/helpers'
import {
   SEND_FEEDBACK,
} from '../actions/types';

import {
sendFeedbackSuccess,
sendFeedbackFailure
} from '../actions';




// Get default consents from the state...
export const settings = (state) => state.settings;

// Get user consents for the default domain
const feedback = async (model) => {
    return await getAxiosRequest().post(`/user/contactus?message=`+ model, null);
}



/**
 * Send Feedback
 */
function* sendFeedback() {
    try {
        let model = yield select(settings); // <-- get the model
        const response = yield call(feedback,model.feedback);
        yield put(sendFeedbackSuccess(response.data));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(sendFeedbackFailure(error));
    }
}


export const feedbackSagas = [
    takeEvery('SEND_FEEDBACK', sendFeedback),
]
  


/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...feedbackSagas,
      ])
}