/**
 * Todo App Actions
 */
import {
    GET_DEFAULT_DOMAIN_CONSENTS,
    GET_DEFAULT_DOMAIN_SUCCESS,
    GET_DEFAULT_DOMAIN_FAILURE,
    ON_CHECK_BOX_TOGGLE_DEFAULT_DOMAIN_CONSENT_ITEM,
    SEARCH_DEFAULT_DOMAIN_CONSENT,
    UPDATE_DEFAULT_DOMAIN_CONSENT_SEARCH,
    UPDATE_DEFAULT_CONSENTS,
    UPDATE_DEFAULT_CONSENTS_SUCCESS,
    UPDATE_DEFAULT_CONSENTS_FAILURE,
    UPDATE_FIRST_LOGIN_STATUS,
    UPDATE_FIRST_LOGIN_STATUS_SUCCESS,
    UPDATE_FIRST_LOGIN_STATUS_FAILURE
} from './types';

/**
 * Redux Action To Fetch Consents of Default Domain
 */
export const getDefaultDomainConsents = () => ({
    type: GET_DEFAULT_DOMAIN_CONSENTS
})

export const getDefaultDomainSuccess = (response) => ({
    type: GET_DEFAULT_DOMAIN_SUCCESS,
    payload: response
});

export const getDefaultDomainFailure = (error) => ({
    type: GET_DEFAULT_DOMAIN_FAILURE,
    payload: error
});


/**
 * Redux Action To Update Consents of Default Domain
 */
export const updateDefaultDomainConsents = () => ({
    type: UPDATE_DEFAULT_CONSENTS
})

export const updateDefaultDomainSuccess = (response) => ({
    type: UPDATE_DEFAULT_CONSENTS_SUCCESS,
    payload: response
});

export const updateDefaultDomainFailure = (error) => ({
    type: UPDATE_DEFAULT_CONSENTS_FAILURE,
    payload: error
});

/**
 * Redux Action On Toggle Checkbox Todo Item
 */
export const onCheckBoxToggleDefaultConsentItem = (consent) => ({
    type: ON_CHECK_BOX_TOGGLE_DEFAULT_DOMAIN_CONSENT_ITEM,
    payload: consent
});

/**
 * Redux Action On Search Consents
 */
export const onSearchDefaultConsent = (searchText) => ({
    type: SEARCH_DEFAULT_DOMAIN_CONSENT,
    payload: searchText
});

/**
 * Redux Action To Update Consent Search
 */
export const updateDefaultConsentSearch = (value) => ({
    type: UPDATE_DEFAULT_DOMAIN_CONSENT_SEARCH,
    payload: value
});


/**
 * Redux Action To Update First Login Status of user
 */
export const updateFirstLoginStatus = () => ({
    type: UPDATE_FIRST_LOGIN_STATUS
    
})

export const updateFirstLoginStatusSuccess = (response) => ({
    type: UPDATE_FIRST_LOGIN_STATUS_SUCCESS,
    payload: response
});

export const updateFirstLoginStatusFailure = (error) => ({
    type: UPDATE_FIRST_LOGIN_STATUS_FAILURE,
    payload: error
});
