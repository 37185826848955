import React from 'react';
import Header from "./Components/Header/HeaderMenu";
import Footer from "./Components/FooterMenu/index";
import {withRouter} from 'react-router-dom'



const Layout = (props)=>{
    
    return(
        <>
            <Header/>
            {props.children}
            <Footer active={props.location}/>
        </>
    )
}

export default withRouter(Layout) 