/**
 * Default Consent Reducer
 */
/* eslint-disable */
import consents from '../assets/data/manageconsents/consents';
import * as _ from 'lodash';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import React  from 'react';

import IntlMessages from '../util/IntlMessages';
// action types
import {
    GET_DEFAULT_DOMAIN_CONSENTS,
    GET_DEFAULT_DOMAIN_SUCCESS,
    GET_DEFAULT_DOMAIN_FAILURE,
    ON_CHECK_BOX_TOGGLE_DEFAULT_DOMAIN_CONSENT_ITEM,
    SEARCH_DEFAULT_DOMAIN_CONSENT,
    UPDATE_DEFAULT_DOMAIN_CONSENT_SEARCH,
    UPDATE_DEFAULT_CONSENTS,
    UPDATE_DEFAULT_CONSENTS_SUCCESS,
    UPDATE_DEFAULT_CONSENTS_FAILURE,
    UPDATE_FIRST_LOGIN_STATUS,
    UPDATE_FIRST_LOGIN_STATUS_SUCCESS,
    UPDATE_FIRST_LOGIN_STATUS_FAILURE

} from '../actions/types';

// initial state
const INIT_STATE = {
    consents:[],
    selectedConsents: 0,
    filteredConsents:[],
    searchConsent: ''
};


const Message = ({ id }) => (
    <IntlMessages id={id} />
);


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get default domain consents
        case GET_DEFAULT_DOMAIN_CONSENTS:
            return { ...state, loading:true };
        // Success in getting default domain consents, set the response in the state...
        case GET_DEFAULT_DOMAIN_SUCCESS:
            return { ...state, loading:false, consents:action.payload.data.consents, filteredConsents:action.payload.data.consents };           
        // failure in fetching the default domain data    
        case GET_DEFAULT_DOMAIN_FAILURE:
        NotificationManager.success(Message({id:"defaultdomain.loadFailure"}));
            return { ...state, loading: false };             
        // Update default domain consents
        case UPDATE_DEFAULT_CONSENTS:
            return { ...state, loading:true, consents:state.consents };
        // success in updating default domain consents
        case UPDATE_DEFAULT_CONSENTS_SUCCESS:
            NotificationManager.success(Message({id:"defaultdomain.updateSuccess"}));
            return { ...state, loading:false };
        //failure to update the default domain consents           
        case UPDATE_DEFAULT_CONSENTS_FAILURE:
        NotificationManager.error(Message({id:"defaultdomain.updateFailure"}));
            return { ...state, loading: false };             
        // Search consents    
        case SEARCH_DEFAULT_DOMAIN_CONSENT:
        if (action.payload === '') {
            return { ...state, filteredConsents: state.consents };
        } else {
            const filtered = state.filteredConsents.filter((item) =>
                item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
            return { ...state, filteredConsents: filtered }
        }

        // update consent search
        case UPDATE_DEFAULT_DOMAIN_CONSENT_SEARCH:
        return { ...state, searchConsent: action.payload };
        // If the user changes the state of a consent item
        case ON_CHECK_BOX_TOGGLE_DEFAULT_DOMAIN_CONSENT_ITEM:
        // Get current datetime to update
        var dateTime = new Date();
        //dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss.SSS'Z'");
        if (action.payload === null) {
            return { ...state, filteredConsents: state.consents };
        } else {
            const filtered = _.filter(state.filteredConsents, {name:action.payload.name});
            filtered[0].value = !filtered[0].value;
            filtered[0].lastUpdated = dateTime.toISOString(); 
            return Object.assign({}, state, {
                filteredConsents:state.filteredConsents
            })
        }

        case UPDATE_FIRST_LOGIN_STATUS:
            return { ...state, loading:true };
        case UPDATE_FIRST_LOGIN_STATUS_SUCCESS:
            localStorage.removeItem('firstLogin');
            return { ...state, loading:false };
        case UPDATE_FIRST_LOGIN_STATUS_FAILURE:
            return { ...state, loading:false };
        

        default: return { ...state };

    }
}
