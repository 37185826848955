
import { 
    GET_SEGMENTS ,
    GET_SEGMENTS_SUCCESS,
    GET_SEGMENTS_FALIURE,
    SAVE_SEGMENTS ,
    SAVE_SEGMENTS_SUCCESS,
    SAVE_SEGMENTS_FALIURE,
    ADD_SIMILER_SEGMENTS,
    ADD_USER_SEGMENTS,
    REMOVE_SEGMENTS,
    CHANGE_WEIGHT ,
    GET_GLOBAL_SEGMENTS,
    GET_GLOBAL_SEGMENTS_SUCCESS,
    GET_GLOBAL_SEGMENTS_FALIURE,
    SHOW_DOMAINS,
    SHOW_SEGMENTS,
    FILTER_SEGMENTS,
    SELECT_SEGEMENTS,
    DESELECT_SEGEMENTS
    } from'./types'

    export const getSegment = () => ({
        type: GET_SEGMENTS
    });
    export const getSegmentSuccess = (res) => ({
        type: GET_SEGMENTS_SUCCESS,
        payload:res
    });
    export const getSegmentFaliure = (error) => ({
        type: GET_SEGMENTS_FALIURE,
        payload:error
    });

    export const getGlobalSegment = () => ({
        type: GET_GLOBAL_SEGMENTS
    });
    export const getGlobalSegmentSuccess = (res) => ({
        type: GET_GLOBAL_SEGMENTS_SUCCESS,
        payload:res
    });
    export const getGlobalSegmentFaliure = (error) => ({
        type: GET_GLOBAL_SEGMENTS_FALIURE,
        payload:error
    });
    export const saveSegment = (data) => ({
        type: SAVE_SEGMENTS,
        payload:data
    });
    export const saveSegmentSuccess = (res) => ({
        type: SAVE_SEGMENTS_SUCCESS,
        payload:res
    });

    export const saveSegmentFaliure = (error) => ({
        type: SAVE_SEGMENTS_FALIURE,
        payload:error
    });



    export const changeWeight = (id,value) => ({
        type: CHANGE_WEIGHT,
        payload:id,
        value:value
    });
    export const removeSegment = (id) => ({
        type: REMOVE_SEGMENTS,
        payload:id
        })
    export const addSimilerSegment = (id) => ({
        type: ADD_SIMILER_SEGMENTS,
        payload:id
        })
    export const addUserSegment=(value)=>({
        type:ADD_USER_SEGMENTS,
        payload:value
        })
    export const showdomains=()=>({
        type:SHOW_DOMAINS,
        
    })
    export const showsegments=(domain)=>({
        type:SHOW_SEGMENTS,
        payload:domain
    })
    export const filtersegments=(filterdomains)=>({
        type:FILTER_SEGMENTS,
        payload:filterdomains
    })
    export const selectsegment=(segment)=>({
        type:SELECT_SEGEMENTS,
        payload:segment
    })
    export const deselectsegment=(segment)=>({
        type:DESELECT_SEGEMENTS,
        payload:segment
    })