

import * as _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import React from 'react';
import IntlMessages from '../util/IntlMessages';
import AppConfig from '../constants/AppConfig'

import {
  GET_SEGMENTS,
  GET_SEGMENTS_SUCCESS,
  GET_SEGMENTS_FALIURE,
  SAVE_SEGMENTS,
  SAVE_SEGMENTS_SUCCESS,
  SAVE_SEGMENTS_FALIURE,
  ADD_SIMILER_SEGMENTS,
  ADD_USER_SEGMENTS,
  REMOVE_SEGMENTS,
  CHANGE_WEIGHT,
  GET_GLOBAL_SEGMENTS,
  GET_GLOBAL_SEGMENTS_SUCCESS,
  GET_GLOBAL_SEGMENTS_FALIURE,
  SHOW_DOMAINS,
  SHOW_SEGMENTS,
  FILTER_SEGMENTS,
  SELECT_SEGEMENTS,
  DESELECT_SEGEMENTS
} from '../actions/types'
import { showdomains } from '../actions'

// initial state 
const INIT_STATE = {
  profileSegments: [


  ],
  similarSegments: [],
  attribute: '',
  loading: false,
  plotdata: null,
  filteredSimilarSegments: [],
  newStructure: [],
  domains: [],
  segmentsSelected: []

}
const color = ['#9FCBE9', '#6EB6E7', "#1E93E3", "#0F639D", '#9FCBE9', '#6EB6E7', "#1E93E3"]

const Message = ({ id }) => (
  <IntlMessages id={id} />
);
export default (state = INIT_STATE, action) => {

  switch (action.type) {
    case GET_SEGMENTS:
      return { ...state, loading: true }
    case GET_SEGMENTS_SUCCESS:

      if (action.payload.data.segmentDataSet) {
        // check if the global segments are already loaded.
        if (state.similarSegments.length > 0) {
          action.payload.data.segmentDataSet.forEach(element => {
            _.remove(state.similarSegments, { id: element.id });
          });
        }
        var profilesegment = action.payload.data.segmentDataSet
        var unique = _.uniq(_.map(profilesegment, _.property('domain')))

        var newStructure = unique.map((domain, index) => {
          return (
            _.filter(profilesegment, { 'domain': domain })

          )
        })
        var plotdata = {
          "name": "gravito",
          "children": [

          ]
        }
        newStructure.map((domain, index) => {
          let domainleaf = {
            "name": unique[index],
            "children": [],
          }
          domain.map((segment, i) => {
            let newSegement = {
              "name": segment.name,
              "size": 3812,
              "children": []
            }
            domainleaf.children.push(newSegement)
          })
          plotdata.children.push(domainleaf)


        })

        return { ...state, loading: false, profileSegments: action.payload.data.segmentDataSet, plotdata: plotdata, filteredSimilarSegments: [] };
      } else {
        return { ...state, loading: false, profileSegments: [] };
      }
    case GET_SEGMENTS_FALIURE:
      NotificationManager.error(Message({ id: "dashboard.segmentFailure" }));
      return { ...state, loading: false };

    case GET_GLOBAL_SEGMENTS:
      return { ...state, loading: true }
    case GET_GLOBAL_SEGMENTS_SUCCESS:

      
 
      if (action.payload.data) {
        var unique = _.uniq(_.map(action.payload.data, _.property('domain')))
        var filteredSegments = [];
        unique.forEach(domain => {
          var domainSegments = _.filter(action.payload.data, { 'domain': domain });
          filteredSegments = _.union(filteredSegments, domainSegments);
          
        });


  
        
        var segmentData = [];
        filteredSegments.forEach(element => {
          var segment = {
            "name": element.name,
            "id": element.id,
            "weight": element.weight,
            "origin": element.origin,
            "domain": element.domain
          }
          segmentData.push(segment);
        });

        return { ...state, loading: false, similarSegments: segmentData };
      } else {
        return { ...state, loading: false, similarSegments: [] };
      }
    case GET_GLOBAL_SEGMENTS_FALIURE:
      NotificationManager.error(Message({ id: "dashboard.segmentFailure" }));
      return { ...state, loading: false };



    case SAVE_SEGMENTS:
      return { ...state, loading: true, profileSegments: state.profileSegments };
    case SAVE_SEGMENTS_SUCCESS:
      NotificationManager.success(Message({ id: "dashboard.updateSegmentSuccess" }));
      window.location = `${AppConfig.appUrl}`
      return { ...state, loading: false };
    case SAVE_SEGMENTS_FALIURE:
      NotificationManager.error(Message({ id: "dashboard.updateSegmentFailure" }));
      return { ...state, loading: false };


    case CHANGE_WEIGHT:
      var profiles = _.toArray({ ...state.profileSegments });
      var profileSegment = _.filter(profiles, { 'id': action.payload })[0];
      if (profileSegment.weight > -101 && action.value < 101) {
        profileSegment.weight = action.value;
        state.profileSegments = profiles;
      }
      return { ...state }
    case REMOVE_SEGMENTS:
      profiles = _.toArray({ ...state.profileSegments });
      _.remove(profiles, { 'id': action.payload });
      _.remove(state.filteredSimilarSegments, { 'id': action.payload });
      state.profileSegments = profiles;
      return { ...state }

    case ADD_SIMILER_SEGMENTS:
      // Get the segment from the similarsegments array by id
      var similarSegment = _.filter({ ...state.similarSegments }, { 'id': action.payload })[0];
      var copysimilerseg = _.clone(similarSegment, true);
      // load profile segments 
      profiles = _.toArray({ ...state.profileSegments });


      // check if the segment already exists
      if (_.filter(profiles, { 'name': copysimilerseg.name }).length === 0) {
        // Push to profiles array 
        profiles.push(copysimilerseg);
      }

      _.remove(state.similarSegments, { id: action.payload });
      _.remove(state.filteredSimilarSegments, { id: action.payload });
      // Set state with new array
      state.profileSegments = profiles;
      return { ...state }

    case ADD_USER_SEGMENTS:
      if ({ ...state.attribute } != null && { ...state.attribute } !== '') {
        profiles = _.toArray({ ...state.profileSegments });
        profiles.push({ name: action.payload, id: '', weight: 40, origin: 'userentered' });
        state.profileSegments = profiles;
      }
      return { ...state }
    case SHOW_DOMAINS:
      var profilesegment = state.profileSegments
      var unique = _.uniq(_.map(profilesegment, _.property('domain')))
      state.domains = unique
      state.newStructure = unique.map((domain, index) => {
        return (
          _.filter(profilesegment, { 'domain': domain })

        )
      })
      state.newStructure.map((domain, index) => {
        let domainleaf = {
          title: index,
          label: unique[index],
          color: color[index],
          size: 10,
          radius: 90,
          radius0: 30,
          children: [],

        }
        state.plotdata.children.push(domainleaf)


      })
      return ({ ...state })

    case SHOW_SEGMENTS:

      state.newStructure.map((domainArray, index) => {
        
        if (domainArray[0].domain == action.payload && !_.find(state.plotdata.children, ['label', action.payload]).children.length) {
          domainArray.map((segment) => {
            let newSegement = {
              title: segment.name,
              label: segment.name,
              size: 3,
              radius: 150,
              radius0: 100,
              color: 'white',
            }
            _.find(state.plotdata.children, ['label', action.payload]).children.push(newSegement)
          })
        }
        return { ...state }

      })
    case FILTER_SEGMENTS:

      if (action.payload.length != 0) {
        var filterArray = action.payload
        var filterSegments = []
        filterArray.map((domainName, i) => {
          filterSegments = [...filterSegments, ..._.filter(state.similarSegments, { "domain": domainName })]
        })
        return { ...state, filteredSimilarSegments: filterSegments }
      }
      else {
        return ({ ...state, filteredSimilarSegments: [] })
      }
    case SELECT_SEGEMENTS:
      let segementToAdd = action.payload
      //add to selectedSegementArray
      state.segmentsSelected.push(segementToAdd)
      //remove segement from similarSegmentdataset

      let newSimalarsegment = _.remove(state.similarSegments, function (seg) {
        return seg == segementToAdd
      })
      _.remove(state.filteredSimilarSegments, function (seg) {
        return seg == segementToAdd
      })

      return ({ ...state })
    case DESELECT_SEGEMENTS:
      let segementToRemove = action.payload;
      //add it to similar segment
      state.similarSegments.push(segementToRemove)
      //remove segement form selectedSegment
      _.remove(state.segmentsSelected, function (seg) {
        return seg == segementToRemove
      })
      return ({ ...state })




    default:
      return { ...state };
  }
}