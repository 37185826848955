/**
 * Todo Sagas
 */
import { all, call, put, takeEvery,select } from 'redux-saga/effects';
import axios from 'axios';
// app config
import AppConfig from '../constants/AppConfig';
import {getAxiosRequest} from '../helpers/helpers'

import {
    getUserDomainSuccess,
    getUserDomainFailure,
    getMyConsentsSuccess,
    getMyConsentsFailure,
    deleteDomainConsentsSuccess,
    deleteDomainConsentsFailure,
    deleteDomainDataConsentsSuccess,
    deleteDomainDataConsentsFailure,
} from '../actions';



/**
 * Send Domain Request To Servercons
 * The url will be changed to api url later
 */



// Get manage consents state
export const myConsents = (state) => state.mydataReducer;

// Get consents from Server
const UserConsents = async (model) => {
    return await getAxiosRequest().get('/consent');
}

// Get data consents from Server
const MydataConsents = async (domain) => {
    return await getAxiosRequest().get(`/consent/all?domain=`+ domain);
}


// Method to delete the consents for the selected domain
const deleteConsents = async (model) => {
    return await getAxiosRequest().delete(`consent/domain?domain=`+ model.domain);
}

// Method to delete the data consents for the selected domain
const deleteDataConsents = async (model) => {
    return await getAxiosRequest().delete(`consent/data/domain?domain=`+ model.domain);
}


/**
 * Get Domains and Consents
 */
function* getDomainsFromServer() {
    try {
        const response = yield call(UserConsents);
        yield put(getUserDomainSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
            yield put(getUserDomainFailure(error));
        
        
    }
}

/**
 * Get Data Consents from Server
 */
function* getMyConsents() {
    try {
        let model = yield select(myConsents); // <-- get the model

        const response = yield call(MydataConsents, model.selectedDomain);
        yield put(getMyConsentsSuccess(response));
        
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
            yield put(getMyConsentsFailure(error));
        
        
    }
}


/**
 * Delete Selected Domain Consents 
 */
function* deleteDomainConsents() {
    try {
        let model = yield select(myConsents); // <-- get the model
        if(model.selectedDomain){
            var consentDeleteModel = {
                domain:model.selectedDomain,
            }
            const response = yield call(deleteConsents, consentDeleteModel);
            yield put(deleteDomainConsentsSuccess(response));
        }
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(deleteDomainConsentsFailure(error));
    }
}

/**
 * Delete Selected Domain Data Consents 
 */
function* deleteDomainDataConsents() {
    try {
        let model = yield select(myConsents); // <-- get the model
        var consentDeleteModel = {
            domain:model.selectedDomain,
        }

        
        const response = yield call(deleteDataConsents, consentDeleteModel);
        yield put(deleteDomainDataConsentsSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(deleteDomainDataConsentsFailure(error));
    }
}



export const myDataSagas = [
    takeEvery('GET_USER_DOMAINS', getDomainsFromServer),
    takeEvery("SELECT_MY_CONSENTS_DOMAIN", getMyConsents),
    takeEvery("DELETE_CONSENT_FOR_DOMAIN", deleteDomainConsents),
    takeEvery("DELETE_DATA_CONSENT_FOR_DOMAIN", deleteDomainDataConsents)
]
  


/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...myDataSagas,
      ])
}
