import React, { useEffect,useContext } from "react";
import IntlMessages from "../../util/IntlMessages";
import { Button } from "semantic-ui-react";
import {withRouter} from "react-router-dom"
import AuthContext from "../../context/AuthContext"
import AppConfig from '../../constants/AppConfig'
import { UserManager } from 'oidc-client';
var querystring = require('querystring');

let manager = new UserManager(AppConfig.identityConfig);


// PKCE HELPER FUNCTIONS

// Generate a secure random string using the browser crypto functions
function generateRandomString() {
    var array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ('0' + dec.toString(16)).substr(-2)).join('');
}

// Calculate the SHA256 hash of the input text. 
// Returns a promise that resolves to an ArrayBuffer
function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
}

// Base64-urlencodes the input string
function base64urlencode(str) {
    // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
    // btoa accepts chars only within ascii 0-255 and base64 encodes them.
    // Then convert the base64 encoded to base64url encoded
    //   (replace + with -, replace / with _, trim trailing =)
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
        .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}


// Return the base64-urlencoded sha256 hash for the PKCE challenge
async function pkceChallengeFromVerifier(v) {
    let hashed = await sha256(v);
    return base64urlencode(hashed);
}

async function getAuthorized(){
    // Create and store a random "state" value
    var state = generateRandomString();
    localStorage.setItem("pkce_state", state);

    // Create and store a new PKCE code_verifier (the plaintext random secret)
    var code_verifier = generateRandomString();
    localStorage.setItem("pkce_code_verifier", code_verifier);

    // Hash and base64-urlencode the secret to use as the challenge
    var code_challenge = await pkceChallengeFromVerifier(code_verifier);

    // let promise = Promise.resolve(manager.metadataService.getAuthorizationEndpoint());
    // let authorizationEndPoint = await promise;

    // Build the authorization URL
    var url = AppConfig.identityConfig.authorize_endpoint
        + "?response_type=code"
        + "&client_id="+encodeURIComponent(AppConfig.identityConfig.client_id)
        + "&state="+encodeURIComponent(state)
        + "&scope="+encodeURIComponent(AppConfig.identityConfig.scope)
        + "&redirect_uri="+encodeURIComponent(AppConfig.identityConfig.redirect_uri)
        + "&code_challenge="+encodeURIComponent(code_challenge)
        + "&code_challenge_method=S256"
        ;


        window.location = url;
}


const FullScreenSlider = (props) => {
    const authcontext=useContext(AuthContext)
    
    const elements = [
        {
            "id": 1,
            "title": "Manage your data",
            "body": "With Gravito you can easily manage all data permissions by choosing what you want to show and share with whom."
        },
        {
            "id": 2,
            "title": "Manage your profile",
            "body": "You can review, modify and cancel the permissions per service provider/domain or individually for each channel/device."
        },
        {
            "id": 3,
            "title": "Say no",
            "body": "No more random marketing messages."
        }
    ]
    const goToLogin=()=>{
        if(authcontext.isAuth){
            props.history.push('/dashboard')
        }
        else{
            //props.history.push('/login')
            getAuthorized();
        }
        
    }

    const updateAppVersion = ()=>{
        localStorage.setItem('appversion', AppConfig.appVersion);
        window.location.reload();
    }

    return (

        <>
            <div id="page-transitions" className="page-build light-skin highlight-blue">


                <div className="page-content page-content-full">
                    <div className="coverpage">

                        <div className="cover-slider owl-carousel owl-has-dots-over owl-loaded owl-drag">

                            <div className="cover-item background-Image-201">
                                <div className="cover-content cover-content-center">
                                    <h1 className="color-white center-text bolder ultrabold bottom-30">{elements[0].title}</h1>
                                    <div className="deco-thick bg-green-dark bottom-30"></div>
                                    <p className="color-white center-text opacity-70 bottom-30">{elements[0].body}</p>
                                    <button onClick={goToLogin} className="button button-green color-white button-round button-center uppercase ultrabold button-s">Get Started</button>
                                    {localStorage.getItem('appversion') && Number(localStorage.getItem('appversion')) < Number(AppConfig.appVersion)?<div>
                                        <div className="top-30"></div>
                                        <button onClick={updateAppVersion} style={{width:'250px'}} className="button button-red color-white button-round button-center button-s ultrabold">Update to new version</button>
                                    </div>:null}
                                    
                                </div>
                                <div className="cover-overlay overlay bg-black opacity-80"></div>

                            </div>
                            
                            <div className="cover-item background-Image-202">
                                <div className="cover-content cover-content-center">
                                    <h1 className="color-white center-text bolder ultrabold bottom-30">{elements[1].title}</h1>
                                    <div className="deco-thick bg-green-dark bottom-30"></div>
                                    <p className="color-white center-text opacity-70 bottom-30">{elements[1].body}</p>
                                    <button onClick={goToLogin} className="button button-green color-white button-round button-center uppercase ultrabold button-s">Get Started</button>
                                    {localStorage.getItem('appversion') && Number(localStorage.getItem('appversion')) < Number(AppConfig.appVersion)?<div>
                                        <div className="top-30"></div>
                                        <button onClick={updateAppVersion} style={{width:'250px'}} className="button button-red color-white button-round button-center button-s ultrabold">Update to new version</button>
                                    </div>:null}
                                </div>
                                <div className="cover-overlay overlay bg-black opacity-80"></div>

                            </div>
                            
                            <div className="cover-item background-Image-203">
                                <div className="cover-content cover-content-center">
                                    <h1 className="color-white center-text bolder ultrabold bottom-30">{elements[2].title}</h1>
                                    <div className="deco-thick bg-green-dark bottom-30 "></div>
                                    <p className="color-white center-text opacity-70 bottom-30">{elements[2].body}</p>
                                    <button onClick={goToLogin} className="button button-green color-white button-round button-center uppercase ultrabold button-s">Get Started</button>
                                    {localStorage.getItem('appversion') && Number(localStorage.getItem('appversion')) < Number(AppConfig.appVersion)?<div>
                                        <div className="top-30"></div>
                                        <button onClick={updateAppVersion} style={{width:'250px'}} className="button button-red color-white button-round button-center button-s ultrabold">Update to new version</button>
                                    </div>:null}
                                </div>
                                <div className="cover-overlay overlay bg-black opacity-80"></div>

                            </div>

                        </div>
                       
                    </div>
                    
                  </div>
            </div>
        </>
    )
}
export default
withRouter(FullScreenSlider) 