import React ,{Component} from "react"
import {useEffect} from "react"
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import Loader from "../../components/Loader/loader"
import * as qs from 'query-string';
import {getJwtToken,tokenFailure,tokenSuccess} from "../../actions"

const MagicLinkCofirmation= (props)=>{
    useEffect(()=>{
        
        let tokenObj = qs.parse(props.location.search)
        
        props.getJwtToken(tokenObj)
    },[])
    
    return(
        <>
         
        <Loader></Loader>
        </>
      
    )
}
const mapStateToProps = ({ magicLinkUser }) => {
    return magicLinkUser;
  }
  
export default connect(mapStateToProps, {
    getJwtToken,
    tokenSuccess,
    tokenFailure  
})(withRouter(MagicLinkCofirmation))


