import React ,{Component} from "react"
import {useEffect} from "react"
import { connect } from 'react-redux';
import {withRouter} from "react-router-dom";
import Loader from "../../components/Loader/loader"
import * as qs from 'query-string';
import {deleteUser} from "../../actions"

const DeleteProfile =(props)=>{
    useEffect(()=>{
        let model = qs.parse(props.location.search)
        
        props.deleteUser(model)
    },[])
    return(
        <>
        <Loader></Loader>
        </>
    )
}

const mapStateToProps = ({ authUser }) => {
    return authUser;
  }

export default connect(mapStateToProps,{

deleteUser
})(withRouter(DeleteProfile))