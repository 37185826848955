import React, { Component } from 'react';
import { connect } from 'react-redux';

import IntlMessages from '../../../util/IntlMessages';
import { Dropdown, Select } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import {
    getUserDomains, selectUserDomain, getObservations, onSelectDomainAction,
    fetchSelectedDomainConsents,
    getDomains
} from '../../../actions';

class DomainSearch extends Component {
    state = {
        domainDropdownOpen: false
    }



    // function to toggle dropdown menu
    toggle = () => {
        this.setState({
            domainDropdownOpen: !this.state.domainDropdownOpen
        });
    }

    // on change language
    onChangeDomain(e, data) {
        var domain = data.value

        this.props.onSelectDomainAction({ domain });
        this.props.selectUserDomain({ domain })
    }


    componentWillMount() {
        this.fetchDomains();


    }
    // componentDidMount = () => {
    //     debugger
    //     if (this.props.domainName) {
    //         console.log(this.props)
    //         //     this.props.onSelectDomainAction({ domain });
    //         // this.props.selectUserDomain({domain})
    //     }
    // }
    componentDidUpdate = (prevProps) => {
        
        if (prevProps.domains == '' && this.props.domains != '') {
            if (this.props.domainName&&this.props.domainName.domain) {
                let queryDomain = this.props.domainName.domain
                this.props.onSelectDomainAction({domain: queryDomain });
                this.props.selectUserDomain({domain: queryDomain })
            }
            else{
                let domain = this.props.domains[0]
                this.props.onSelectDomainAction({ domain });
                this.props.selectUserDomain({ domain })
            }
            
        }
    }

    /**
     * Fetch Recent User
     */
    fetchDomains() {
        this.props.getDomains();
    }
    render() {


        const { domains, selectedDomain } = this.props;
        

        const option = domains.map((domains, key) => {
            return (
                {
                    key: key,
                    value: domains,
                    text: domains
                }
            )
        })
       
        return (
            <>
                <Dropdown

                    inline
                    options={option}
                    scrolling={true}
                    value={selectedDomain}
                    onChange={(e, data) => { this.onChangeDomain(e, data) }}
                />

            </>
        )
    }
}

const mapStateToProps = ({ manageConsentsReducer }) => {
    return manageConsentsReducer;
};

export default connect(mapStateToProps, {
    getDomains,
    getUserDomains,
    selectUserDomain,
    getObservations,
    onSelectDomainAction,

})(DomainSearch)