/**
 * User Profile Actions
 */
import {
    GET_USER_PROFILE,
    GET_USER_PROFILE_SUCCESS,
    GET_USER_PROFILE_FAILURE,
    REFRESH_USER_PROFILE,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_SUCCESS,
    UPDATE_USER_PROFILE_FAILURE,
    ADD_SOCIAL_LOGIN_TO_PROFILE,
    ADD_SOCIAL_LOGIN_TO_PROFILE_SUCCESS,
    ADD_SOCIAL_LOGIN_TO_PROFILE_FAILURE,
    DELETE_SOCIAL_LOGIN_FROM_PROFILE,
    DELETE_SOCIAL_LOGIN_FROM_PROFILE_SUCCESS,
    DELETE_SOCIAL_LOGIN_FROM_PROFILE_FAILURE,
    DOWNLOAD_PROFILE,
    DOWNLOAD_PROFILE_SUCCESS,
    DOWNLOAD_PROFILE_FAILURE, 
    MERGE_PROFILE, 
    MERGE_PROFILE_SUCCESS,
    MERGE_PROFILE_FAILURE,
    GET_CURRENT_SYSTEM_ROLES,
    GET_CURRENT_SYSTEM_ROLES_SUCCESS,
    GET_CURRENT_SYSTEM_ROLES_FAILURE,
    INVITE_FRIEND,
    INVITE_FRIEND_SUCCESS,
    INVITE_FRIEND_FAILURE
} from './types';

/**
 * Redux Action To Fetch Consents of Default Domain
 */
export const getUserProfile = () => ({
    type: GET_USER_PROFILE
})

export const getUserProfileSuccess = (response) => ({
    type: GET_USER_PROFILE_SUCCESS,
    payload: response
});

export const getUserProfileFailure = (error) => ({
    type: GET_USER_PROFILE_FAILURE,
    payload: error
});

export const refreshUserProfile = (response) => ({
    type: REFRESH_USER_PROFILE,
    payload: response
});


/**
 * Redux Action To Update Consents of Default Domain
 */
export const updateUserProfile = () => ({
    type: UPDATE_USER_PROFILE
})

export const updateUserProfileSuccess = (response) => ({
    type: UPDATE_USER_PROFILE_SUCCESS,
    payload: response
});

export const updateUserProfileFailure = (error) => ({
    type: UPDATE_USER_PROFILE_FAILURE,
    payload: error
});


/**
 * Redux Action Social Login Addition 
 */

export const addSocialLogin = (socialResponse) => ({
    type: ADD_SOCIAL_LOGIN_TO_PROFILE,
    payload: socialResponse
});

/**
 * Redux Action Social Login Success
 */
export const addSocialLoginSuccess = (userInfo) => ({
    type: ADD_SOCIAL_LOGIN_TO_PROFILE_SUCCESS,
    payload: userInfo
});


/**
 * Redux Action Social Login Failure
 */
export const addSocialLoginFailure = (error) => ({
    type: ADD_SOCIAL_LOGIN_TO_PROFILE_FAILURE,
    payload: error
})


/**
 * Redux Action Social Login Deletion 
 */

export const deleteSocialLogin = (selectedLogin) => ({
    type: DELETE_SOCIAL_LOGIN_FROM_PROFILE,
    payload: selectedLogin
});

/**
 * Redux Action Social Login Success
 */
export const deleteSocialLoginSuccess = (userInfo) => ({
    type: DELETE_SOCIAL_LOGIN_FROM_PROFILE_SUCCESS,
    payload: userInfo
});


/**
 * Redux Action Social Login Failure
 */
export const deleteSocialLoginFailure = (error) => ({
    type: DELETE_SOCIAL_LOGIN_FROM_PROFILE_FAILURE,
    payload: error
})


/**
 * Redux Action to download user profile
 */

export const downloadProfile = () => ({
    type: DOWNLOAD_PROFILE,
});

/**
 * Redux Action download user profile success
 */
export const downloadProfileSuccess = (profile) => ({
    type: DOWNLOAD_PROFILE_SUCCESS,
    payload: profile
});


/**
 * Redux Action donwload profile failure
 */
export const downloadProfileFailure = (error) => ({
    type: DOWNLOAD_PROFILE_FAILURE,
    payload: error
})


/**
 * Redux Action to merge user profile and observed profile
 */

export const mergeProfile = (OPId) => ({
    type: MERGE_PROFILE,
    payload:OPId
});

/**
 * Redux Action merge user profile success
 */
export const mergeProfileSuccess = (profile) => ({
    type: MERGE_PROFILE_SUCCESS,
    payload: profile
});


/**
 * Redux Action merge profile failure
 */
export const mergeProfileFailure = (error) => ({
    type: MERGE_PROFILE_FAILURE,
    payload: error
})


/**
 * Redux Action To Fetch Current roles of logged in user.
 */
export const getCurrentRoles = () => ({
    type: GET_CURRENT_SYSTEM_ROLES
})

export const getCurrentRolesSuccess = (response) => ({
    type: GET_CURRENT_SYSTEM_ROLES_SUCCESS,
    payload: response
});

export const getCurrentRolesFailure = (error) => ({
    type: GET_CURRENT_SYSTEM_ROLES_FAILURE,
    payload: error
});

/**
 * Redux Action Invite a friend
 */

export const inviteFriend = (email) => ({
    type: INVITE_FRIEND,
    payload: email
});

/**
 * Redux Action invite friend Success
 */
export const inviteFriendSuccess = (response) => ({
    type: INVITE_FRIEND_SUCCESS,
    payload: response
});


/**
 * Redux Action Invite friend Failure
 */
export const inviteFriendFailure = (error) => ({
    type: INVITE_FRIEND_FAILURE,
    payload: error
})
