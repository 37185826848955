import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import { ConsentListCheckBox, DataConsentListCheckBox, ObservationListCheckBox } from "../MydataComponents/CheckBoxes";
import { Container, Row, Col } from 'reactstrap';
import { modifyDataConsentForDomain, getAllConsents } from "../../../actions"


class Consent extends Component {
    modifyCheckBox = (consent) => {
        this.props.onCheckBoxToggleConsentItem(consent)
    }


    render() {
        const { consents, selectedConsents, labels, selectedDomain, classes, theme, searchConsent, loading, dataconsents, observations } = this.props.data
       

        const consent = selectedConsents ? selectedConsents : null
        const dataconsent = dataconsents ? dataconsents.dataConsentSet : null
        let firstdiv = <div><IntlMessages id="mydata.selectdomain" /></div>
        let seconddiv = <div><IntlMessages id="mydata.selectdomain" /></div>
        let thirddiv = null
        let fourthdiv = null
        if (consent) {
            firstdiv = consent.map((data, index) => {

                if (data.type == 0) {

                    return (
                        <div key={index} className="no-padding">
                            <ConsentListCheckBox consent={data} index={index} onCheckBoxToggle={this.modifyCheckBox}></ConsentListCheckBox>
                        </div>
                    )
                }

            })

            seconddiv = consent.map((data, index) => {
                if (data.type == 1) {
                    return (
                        <div key={index} className="no-padding ">
                            <ConsentListCheckBox consent={data} index={index} onCheckBoxToggle={this.modifyCheckBox}></ConsentListCheckBox>
                        </div>
                    )
                }

            })

        }
        if (dataconsent) {
            thirddiv = dataconsent.map((data, index) => {
                return (
                    <div key={index} className="no-padding ">
                        <DataConsentListCheckBox consent={data} index={index} onCheckBoxToggle={this.props.modifyDataConsentForDomain}></DataConsentListCheckBox>
                    </div>
                )
            })
        }

        if (observations) {
            fourthdiv = observations.map((data, index) => {
                return (
                    <div key={index} className="no-padding ">
                        <ObservationListCheckBox observations={data} index={index}></ObservationListCheckBox>
                    </div>
                )
            })
        }



        return (
            <>
                <div style={{ paddingTop: "10px", marginTop: "70px", minWidth: "45px" }} className=" ">
                    <div style={{ marginBottom: "10px", height: "0px" }} className="gallery gallery-wide ">
                    <strong style={{color:"white",backgroundColor:"#ffa100",padding:"10px",width:"100%",marginBottom:"30px",borderRadius:"10px"}} className="regular"><IntlMessages id="component.dataconsenttitle" /></strong>
                        
                    </div>
                    <div style={{ marginLeft: '0px' }} className="container">
                        <div className="row">
                            {firstdiv}
                        </div>
                    </div>

                </div>
                <div style={{ paddingTop: "10px", marginTop: "10px", minWidth: "45px" }} className=" ">
                    
                    <div style={{ marginBottom: "10px", height: "0px" }} className="gallery gallery-wide ">
                    <strong style={{color:"white",backgroundColor:"#ffa100",padding:"10px",width:"100%",marginBottom:"17px",borderRadius:"10px"}} className="regular"><IntlMessages id="component.channelconsenttitle" /></strong>
                        
                    </div>
                    <div style={{ marginLeft: '0px' }} className="container">
                        <div className="row">
                            {seconddiv}
                        </div>
                    </div>
                </div>
                
                    <div style={{ paddingTop: "10px", marginTop: "10px", minWidth: "45px" }} className=" ">
                        <div style={{ marginBottom: "10px", height: "0px" }} className="gallery gallery-wide ">
                    <strong style={{color:"white",backgroundColor:"#ffa100",padding:"10px",width:"100%",borderRadius:"10px"}} className="regular"><IntlMessages id="component.datasetconsenttitle" /></strong>
                        
                    </div>
                        

                        <div style={{ marginLeft: '0px' }} className="container">
                            <div className="row">
                                {dataconsent != null
                                    ? dataconsent.length > 1
                                        ? thirddiv
                                        : <div><IntlMessages id="mydata.nodataconsents" /></div>
                                    : <div><IntlMessages id="mydata.selectdomain" /></div>}
                            </div>
                        </div>
                    </div>

                

               
                <div style={{ paddingTop: "10px", marginTop: "10px", minWidth: "45px" }} className=" ">
                    
                   
                        <div style={{ marginBottom: "10px", height: "0px" }} className="gallery gallery-wide ">
                    <strong style={{color:"white",backgroundColor:"#ffa100",padding:"10px",width:"100%",borderRadius:"10px"}} className="regular"><IntlMessages id="mydata.opheading" /></strong>
                        
                    </div>
                    
                    <div style={{ marginLeft: '0px' }} className="container">
                        <div className="row">
                            {observations != null
                                ? observations.length > 0
                                    ? fourthdiv
                                    : <div><IntlMessages id="mydata.noop" /></div>
                                : <div><IntlMessages id="mydata.selectdomain" /></div>}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}



export default (Consent)
