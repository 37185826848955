/**
 * Helpers Functions
 */
import moment from 'moment';
import axios from 'axios';
import AppConfig from '../constants/AppConfig';



/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
    throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
    let time = timestamp * 1000;
    let formatDate = format ? format : 'MM-DD-YYYY';
    return moment(time).format(formatDate);
}

/**
 * Convert Date To Timestamp
*/
export function convertDateToTimeStamp(date, format) {
    let formatDate = format ? format : 'YYYY-MM-DD';
    return moment(date, formatDate).unix();
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
    let location = url.pathname;
    let path = location.split('/');
    return path[1];
}
export const getAxiosRequest = () => {

    // var requestOptions = {
    //     baseURL: `${AppConfig.apiPath}`,
    //     withCredentials:true      
    //  }
    let identityToken = localStorage.getItem('identityToken'); 
   var  requestOptions = {
        baseURL: `${AppConfig.apiPath}`,
        withCredentials:true,
        headers: {'Authorization': "bearer " + identityToken}   
     } 
    
    var instance = axios.create(requestOptions);
     instance.interceptors.response.use(
         response=>{
             
             return response
            
        
        },error=>{
         
            // debugger
            if(error.response.status===401&&!(error.request.responseURL===`${AppConfig.apiPath}/account/authenticate` || error.request.responseURL===`${AppConfig.apiPath}/account/magiclink` || error.request.responseURL===`${AppConfig.apiPath}/account/logoff` )){
               
                window.location.replace(`${AppConfig.appUrl}`);
            
            }
            return Promise.reject({ ...error })
            
        }
     )
     
   
    return instance;
}

export const getIdentityAxiosRequest = () => {

    
   var  requestOptions = {
        baseURL: `${AppConfig.identityApiPath}`,
        withCredentials:true
    } 
    
    var instance = axios.create(requestOptions);
     instance.interceptors.response.use(
         response=>{
             
             return response
            
        
        },error=>{
         
            // debugger
            if(error.response.status===401&&!(error.request.responseURL===`${AppConfig.identityApiPath}/account/authenticate` || error.request.responseURL===`${AppConfig.identityApiPath}/account/magiclink` || error.request.responseURL===`${AppConfig.identityApiPath}/account/logoff` )){
               
                window.location.replace(`${AppConfig.appUrl}`);
            
            }
            return Promise.reject({ ...error })
            
        }
     )
     
   
    return instance;
}