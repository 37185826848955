/**
 * My Data Dashboard Actions
 */
import {
    GET_MY_CONSENTS,
    GET_MY_CONSENTS_SUCCESS,
    GET_MY_CONSENTS_FAILURE,
    GET_USER_DOMAINS,
    GET_USER_DOMAINS_SUCCESS,
    GET_USER_DOMAINS_FAILURE,
    SELECT_MY_CONSENTS_DOMAIN,
    DOWNLOAD_CONSENTS,
    DOWNLOAD_DATA_CONSENTS,
    DELETE_CONSENT_FOR_DOMAIN,
    DELETE_CONSENT_FOR_DOMAIN_SUCCESS,
    DELETE_CONSENT_FOR_DOMAIN_FAILURE,
    DELETE_DATA_CONSENT_FOR_DOMAIN,
    DELETE_DATA_CONSENT_FOR_DOMAIN_SUCCESS,
    DELETE_DATA_CONSENT_FOR_DOMAIN_FAILURE,
    GET_OBSERVATIONS,
    GET_OBSERVATIONS_SUCCESS,
    GET_OBSERVATIONS_FAILURE,
    MODIFY_DATA_CONSENT_FOR_DOMAIN,
    MODIFY_DATA_CONSENT_FOR_DOMAIN_SUCCESS,
    MODIFY_DATA_CONSENT_FOR_DOMAIN_FAILURE,
    SET_DOMAIN_NAME_FROM_QUERYPRAM,
} from './types';


/**
 * Redux action for getting data consents
 */
export const getMyConsents = (domain) => ({
    type: GET_MY_CONSENTS,
    payload:domain
})

/**
 * Redux action for successful fetching of data consents for a domain
 */

export const getMyConsentsSuccess =(response)=>({
    type: GET_MY_CONSENTS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching data consents for a domain
 */
export const getMyConsentsFailure =(error)=>({
    type: GET_MY_CONSENTS_FAILURE,
    payload: error
})

/**
 * Redux Action Get Domains
 */
export const getUserDomains = () => ({
    type: GET_USER_DOMAINS
});

/**
 * Redux Action Get Domains  Success
 */
export const getUserDomainSuccess = (response) => ({
    type: GET_USER_DOMAINS_SUCCESS,
    payload: response
});

/**
 * Redux Action Get Domains  Failure
 */
export const getUserDomainFailure = (error) => ({
    type: GET_USER_DOMAINS_FAILURE,
    payload: error
});

export const selectUserDomain = (domain) => ({
    type: SELECT_MY_CONSENTS_DOMAIN,
    payload:domain
});


/**
 * Redux Action Download Consents
 */
export const downloadConsents = () => ({
    type: DOWNLOAD_CONSENTS
});

/**
 * Redux Action Download  Data Consents
 */
export const downloadDataConsents = () => ({
    type: DOWNLOAD_DATA_CONSENTS
});


/**
 * Redux action for deleting consents for a domain
 */
export const deleteDomainConsents = () => ({
    type: DELETE_CONSENT_FOR_DOMAIN
})

/**
 * Redux action for successful deletion of  consents for a domain
 */

export const deleteDomainConsentsSuccess =(response)=>({
    type: DELETE_CONSENT_FOR_DOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion data consents for a domain
 */
export const deleteDomainConsentsFailure =(error)=>({
    type: DELETE_CONSENT_FOR_DOMAIN_FAILURE,
    payload: error
})


/**
 * Redux action for deleting consents for a domain
 */
export const deleteDomainDataConsents = () => ({
    type: DELETE_DATA_CONSENT_FOR_DOMAIN
})

/**
 * Redux action for successful deletion of  consents for a domain
 */

export const deleteDomainDataConsentsSuccess =(response)=>({
    type: DELETE_DATA_CONSENT_FOR_DOMAIN_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in deletion data consents for a domain
 */
export const deleteDomainDataConsentsFailure =(error)=>({
    type: DELETE_DATA_CONSENT_FOR_DOMAIN_FAILURE,
    payload: error
})


/**
 * Redux action for getting observations for the domain from observed profile
 */
export const getObservations = (domain) => ({
    type: GET_OBSERVATIONS,
    payload:domain
})

/**
 * Redux action for successful fetching of observations for a domain
 */

export const getObservationsSuccess =(response)=>({
    type: GET_OBSERVATIONS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching Observations for a domain
 */
export const getObservationsFailure =(error)=>({
    type: GET_OBSERVATIONS_FAILURE,
    payload: error
})
export const modifyDataConsentForDomain =(consent)=>{
    
    return({
    type: MODIFY_DATA_CONSENT_FOR_DOMAIN,
    payload:consent
})}
// export const modifyDataSetConsentForDomain =(consent)=>{
    
//     return({
//     type: MODIFY_DATASET_CONSENT_FOR_DOMAIN,
//     payload:consent
// })}

export const setDomainNameFromQuerryPram=(domainName)=>{
    return(
        {
            type:SET_DOMAIN_NAME_FROM_QUERYPRAM,
            payload:domainName
        }
    )
}