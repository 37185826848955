/**
 *Customer Registration Sagas
 */
import { all, call, fork, put, takeEvery,select } from 'redux-saga/effects';
import * as _ from 'lodash'
import {getAxiosRequest} from '../helpers/helpers'

import {
   SAVE_CUSTOMER
} from '../actions/types';

import {
    customerRegistrationSuccess,
    customerRegistrationFailure

} from '../actions';


/**
 * Send Domain Request To Servercons
 * The url will be changed to api url later
 */


// Get customer details from the state...
export const customer = (state) => state.customerReducer;


// Method to update the default consents
const registerCustomer = async (model) => {
    model.primaryEmail = 'change@gravito.net'; // change it after making changes to api
    // Register as a new customer
    return await getAxiosRequest().post('/customer', model);
}




/**
 * Register Customer 
 */
function* registration() {
    try {
        let model = yield select(customer); // <-- get the model
        
       
        const response = yield call(registerCustomer, model.customer);
        yield put(customerRegistrationSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(customerRegistrationFailure(error.response.data.error));
    }
}



export const customerRegistrationSagas = [
    takeEvery('SAVE_CUSTOMER', registration),
]
  


/**
 * Customer Registration Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...customerRegistrationSagas,
      ])
}