/**
 * Todo App Actions
 */
import {
    GET_DOMAIN_CONSENTS,
    GET_DOMAINS,
    FETCH_DOMAINS,
    ON_SELECT_DOMAIN,
    GET_FILTER_DOMAINS,
    GET_ALL_CONSENTS,
    GET_SELECTED_CONSENTS,
    GET_FILTER_CONSENTS,
    ON_CHECK_BOX_TOGGLE_CONSENT_ITEM,
    SELECT_ALL_CONSENTS,
    GET_UNSELECTED_ALL_CONSENT,
    SEARCH_CONSENT,
    SEARCH_DOMAIN,
    UPDATE_DOMAIN_SEARCH,
    UPDATE_CONSENT_SEARCH,
    ON_HIDE_DOMAIN_LOADER,
    ON_SHOW_DOMAIN_LOADER,
    GET_DOMAINS_SUCCESS,
    GET_DOMAINS_FAILURE,
    GET_CONSENT_SUCCESS,
    GET_CONSENT_FAILURE,
    GET_CURRENT,
    UPDATE_SELECTED_DOMAIN_CONSENTS,
    UPDATE_SELECTED_DOMAIN_CONSENTS_SUCCESS,
    UPDATE_SELECTED_DOMAIN_CONSENTS_FAILURE,
    GET_PREFERENCES_DATA_CONSENTS,
    GET_PREFERENCES_DATA_CONSENTS_SUCCESS,
    GET_PREFERENCES_DATA_CONSENTS_FAILURE,
    UPDATE_PREFERENCES_DATA_CONSENT,
    UPDATE_PREFERENCES_DATA_CONSENT_SUCCESS,
    UPDATE_PREFERENCES_DATA_CONSENT_FAILURE,
} from './types';

/**
 * Redux Action To GET current state
 */
export const getCurrent = () => ({
    type: GET_CURRENT
})


/**
 * Redux Action Get Domains and Consents
 */
export const getDomains = () => ({
    type: GET_DOMAINS
});

/**
 * Redux Action Get Domains and Consents Success
 */
export const getDomainSuccess = (response) => ({
    type: GET_DOMAINS_SUCCESS,
    payload: response
});

/**
 * Redux Action Get Domains and Consents Failure
 */
export const getDomainFailure = (error) => ({
    type: GET_DOMAINS_FAILURE,
    payload: error
});

/**
 * Redux Action To Update Consents of Selected Domain
 */
export const updateSelectedDomainConsents = () => ({
    type: UPDATE_SELECTED_DOMAIN_CONSENTS
})

export const updateSelectedDomainSuccess = (response) => ({
    type: UPDATE_SELECTED_DOMAIN_CONSENTS_SUCCESS,
    payload: response
});

export const updateSelectedDomainFailure = (error) => ({
    type: UPDATE_SELECTED_DOMAIN_CONSENTS_FAILURE,
    payload: error
});



export const getConsentSuccess = (response) => ({
    type: GET_CONSENT_SUCCESS,
    payload: response.data
});

/**
 * Redux Action Get Todos Failure
 */
export const getConsentFailure = (error) => ({
    type: GET_CONSENT_FAILURE,
    payload: error
});
/**
 * Redux Action To Fetch To Todos
 */
export const fetchDomains = () => ({
    type: FETCH_DOMAINS
})



/**
 * Redux Action To Fetch Selected Domain Consents
 */
export const fetchSelectedDomainConsents = () => ({
    type: GET_DOMAIN_CONSENTS
})
/**
 * Redux Action To Activate The Domain Filter
 */
export const activateDomainFilterAction = (activeFilterIndex) => ({
    type: GET_FILTER_DOMAINS,
    payload: activeFilterIndex
});

/**
 * Redux Action To Activate The Consent Filter
 */
export const activateConsentFilterAction = (activeFilterIndex) => ({
    type: GET_FILTER_CONSENTS,
    payload: activeFilterIndex
});

/**
 * Redux Action On Select Domain 
 */
export const onSelectDomainAction = (domain) => ({
    type: ON_SELECT_DOMAIN,
    payload: domain
});

/**
 * Redux Action To Hide The Loding Indicator
 */
export const hideDomainLoadingIndicatorAction = () => ({
    type: ON_HIDE_DOMAIN_LOADER
});


/**
 * Redux Action To Show Loader
 */
export const showDomainLoadingIndicatorAction = () => ({
    type: ON_SHOW_DOMAIN_LOADER
});


/**
 * Redux Action To Get All Todo
 */
export const getAllConsents = (domain) => ({
    type: GET_ALL_CONSENTS,
    payload: domain
});

/**
 * Redux Action To Get Selected Consents
 */
export const getSelectedConsentsAction = () => ({
    type: GET_SELECTED_CONSENTS
});

/**
 * Redux Action To Get Unselected Consents
 */
export const getUnseletedConsentAction = () => ({
    type: GET_UNSELECTED_ALL_CONSENT
});




/**
 * Redux Action On Toggle Checkbox Todo Item
 */
export const onCheckBoxToggleConsentItem = (consent) => ({
    type: ON_CHECK_BOX_TOGGLE_CONSENT_ITEM,
    payload: consent
});

/**
 * Select All Todo
 */
export const selectAllConsents = () => ({
    type: SELECT_ALL_CONSENTS
});

/**
 * Gel Unselected Todo
 */
export const getUnselectedAllConsents = () => ({
    type: GET_UNSELECTED_ALL_CONSENT
});


/**
 * Search Form Hanlder
 */
export const onSearchDomain = (searchText) => ({
    type: SEARCH_DOMAIN,
    payload: searchText
});

/**
 * Redux Action To Update Domain Search
 */
export const updateDomainSearch = (value) => ({
    type: UPDATE_DOMAIN_SEARCH,
    payload: value
});

/**
 * Redux Action On Search Consents
 */
export const onSearchConsent = (searchText) => ({
    type: SEARCH_CONSENT,
    payload: searchText
});

/**
 * Redux Action To Update Consent Search
 */
export const updateConsentSearch = (value) => ({
    type: UPDATE_CONSENT_SEARCH,
    payload: value
});


/**
 * Redux action for getting data consents
 */
export const getPreferencesDataConsents = (domain) => ({
    type: GET_PREFERENCES_DATA_CONSENTS,
    payload:domain
})

/**
 * Redux action for successful fetching of data consents for a domain
 */

export const getPreferencesDataConsentsSuccess =(response)=>({
    type: GET_PREFERENCES_DATA_CONSENTS_SUCCESS,
    payload: response
})
/**
 * Redux action for failure in fetching data consents for a domain
 */
export const getPreferencesDataConsentsFailure =(error)=>({
    type: GET_PREFERENCES_DATA_CONSENTS_FAILURE,
    payload: error
})


/**
 * Redux action for editing a data consent
 */
export const updatePreferencesDataConsent =(model)=>({
    type: UPDATE_PREFERENCES_DATA_CONSENT,
    payload:model
})
/**
 * Redux action for successful editing of a data consent
 */
export const updatePreferencesDataConsentSuccess =(response)=>({
    type: UPDATE_PREFERENCES_DATA_CONSENT_SUCCESS,
    payload: response
})
/**
 * Redux action for failure to edit a data consent
 */
export const updatePreferencesDataConsentFailure =(error)=>({
    type: UPDATE_PREFERENCES_DATA_CONSENT_FAILURE,
    payload: error
})


