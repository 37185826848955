/**
 * Audit Log Sagas
 */
import { all, call,  put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
// app config
import AppConfig from '../constants/AppConfig';
import {getAxiosRequest} from "../helpers/helpers"

import {
    auditLogSuccess,
    auditLogFailure,
} from '../actions';




// Get default consents from the state...
export const auditLog = (state) => state.auditLog;

// Get user consents for the default domain
const UserAuditLog = async (model) => {
    return await getAxiosRequest().get('/audit/user/consents');
}



/**
 * Get Default Consents From Server
 */
function* getAuditLogFromServer() {
    try {
        const response = yield call(UserAuditLog);
        yield put(auditLogSuccess(response));
    } catch (error) {
        if(error.response && error.response.status===401){
            return null
        }
        yield put(auditLogFailure(error));
        
    }
}


export const auditLogSagas = [
    takeEvery('GET_AUDIT_LOG', getAuditLogFromServer),
]
  


/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...auditLogSagas,
      ])
}