/**
 * Auth User Reducers
 */
import { NotificationManager } from 'react-notifications';
import {
    GET_JWT_TOKEN,
    JWT_SUCCESS,
    JWT_FAILURE,
} from '../actions/types';

import { browserHistory } from 'react-router'
// app config
import AppConfig from '../constants/AppConfig';

import React  from 'react';
import IntlMessages from '../util/IntlMessages';

/**
 * initial auth user
 */
const INIT_STATE = {
    email:'',
    guid:'',
    jwttoken: '',
    loading: false,
    firstLogin:null,
    error:''
};
const Message = ({ id }) => (
    <IntlMessages id={id} />
  );

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // GET JWT TOKEN from the api and persist it in GravitoUid Cookie..
        case GET_JWT_TOKEN:
           
        if (action.payload === '') {
           
            return { ...state, error:'The Magic Link is invalid' };
        } else {
            return { ...state, loading: true, email: action.payload.user.email, guid:action.payload.user.token };
        }
        // Success in getting the JWTToken from the api
        case JWT_SUCCESS:
        // if the jwt token is received successfully then write that to a cookie.
        let cookiedata = {
            roles:action.payload.roles,
            language:action.payload.language
        }

        localStorage.setItem('Roles', JSON.stringify(action.payload.roles));
        localStorage.setItem('firstLogin', action.payload.firstLogin);
        setTimeout(() => {
                      
            if(action.payload.firstLogin){
                window.location = `${AppConfig.appUrl}#/mydefaults`
            }else{
                window.location = `${AppConfig.appUrl}`
            }
       }, 1000);
        return { ...state, loading: false, token: action.payload, firstLogin:action.payload.firstLogin };
        
        // Failure in getting the JWT token
        case JWT_FAILURE:
      
            NotificationManager.error(Message({id:"magiclink.error"}));
            
            setTimeout(() => {
                      
                window.location = `${AppConfig.appUrl}`
          }, 1000);
            return { ...state, loading: false, error:1 };

        default: return { ...state };
    }
}
