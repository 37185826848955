/**
 * App Settings Reducers
 */
import { NotificationManager } from 'react-notifications';

import React  from 'react';
import IntlMessages from '../util/IntlMessages';

 import {
  COLLAPSED_SIDEBAR,
  DARK_MODE,
  BOXED_LAYOUT,
  RTL_LAYOUT,
  MINI_SIDEBAR,
  SEARCH_FORM_ENABLE,
  CHANGE_THEME_COLOR,
  TOGGLE_SIDEBAR_IMAGE,
  SET_SIDEBAR_IMAGE,
  SET_LANGUAGE,
  START_USER_TOUR,
  STOP_USER_TOUR,
  SEND_FEEDBACK,
  SEND_FEEDBACK_FAILURE,
  SEND_FEEDBACK_SUCCESS

} from '../actions/types';

// app config
import AppConfig from '../constants/AppConfig';

/**
 * initial app settings
 */
const INIT_STATE = {
  navCollapsed: AppConfig.navCollapsed,
  darkMode: AppConfig.darkMode,
  boxLayout: AppConfig.boxLayout,
  rtlLayout: AppConfig.rtlLayout,
  miniSidebar: AppConfig.miniSidebar, 
  locale: AppConfig.locale,
  searchFormOpen: false, // search form by default false
  startUserTour: false,
  themes: [
    {
      id: 1,
      name: 'primary'
    },
    {
      id: 2,
      name: 'secondary'
    },
    {
      id: 3,
      name: 'warning'
    },
    {
      id: 4,
      name: 'info'
    },
    {
      id: 5,
      name: 'danger'
    },
    {
      id: 6,
      name: 'success'
    }
  ],
  activeTheme: {
    id: 1,
    name: 'primary'
  },
  // sidebar background image
  // sidebarBackgroundImages: [
  //   require('../assets/img/sidebar-1.jpg'),
  //   require('../assets/img/sidebar-2.jpg'),
  //   require('../assets/img/sidebar-3.jpg'),
  //   require('../assets/img/sidebar-4.jpg'),
  // ],
  sidebarActiveFilter: AppConfig.sidebarActiveFilter, // default sidebar color
  enableSidebarBackgroundImage: AppConfig.enableSidebarBackgroundImage, // default enable sidebar background
  selectedSidebarImage: AppConfig.sidebarImage, // default sidebar background image
  languages: [
    {
      languageId: 'english',
      locale: 'en_US',
      name: 'English',
      icon: 'en',
      momentId:'en'
    },
    {
      languageId: 'finnish',
      locale: 'fi_FI',
      name: 'Finnish',
      icon: 'fi',
      momentId:'fi'
    },
    {
      languageId: 'german',
      locale: 'de_DE',
      name: 'German',
      icon: 'DE',
      momentId:'de'
    },
    {
      languageId: 'swedish',
      locale: 'sv_SE',
      name: 'Swedish',
      icon: 'SE',
      momentId:'sv'
    },
    
  ]

};

const Message = ({ id }) => (
  <IntlMessages id={id} />
);
export default (state = INIT_STATE, action) => {
  switch (action.type) {

    // collapse sidebar
    case COLLAPSED_SIDEBAR:
      return { ...state, navCollapsed: action.isCollapsed };

    // start user tour
    case START_USER_TOUR:
      return { ...state, startUserTour: true };

    // stop user tour
    case STOP_USER_TOUR:
      return { ...state, startUserTour: false };

    // change theme color
    case CHANGE_THEME_COLOR:
      return { ...state, activeTheme: action.payload };

    // dark mode
    case DARK_MODE:
      return { ...state, darkMode: action.payload };

    // boxed layout
    case BOXED_LAYOUT:
      return { ...state, boxLayout: action.payload };

    // rtl layout
    case RTL_LAYOUT:
      return { ...state, rtlLayout: action.payload };

    // mini sidebar
    case MINI_SIDEBAR:
      return { ...state, miniSidebar: action.payload };

    // search form
    case SEARCH_FORM_ENABLE:
      document.body.classList.toggle('search-active', !state.searchFormOpen);
      return { ...state, searchFormOpen: !state.searchFormOpen };

    // togglw sidebar image
    case TOGGLE_SIDEBAR_IMAGE:
      return { ...state, enableSidebarBackgroundImage: !state.enableSidebarBackgroundImage };

    // set sidebar image
    case SET_SIDEBAR_IMAGE:
      return { ...state, selectedSidebarImage: action.payload };

    // set language
    case SET_LANGUAGE:
     
      let locale=JSON.stringify(action.payload)
      localStorage.setItem('locale',locale)
      
      return { ...state, locale: action.payload };

              // send feedback
              case SEND_FEEDBACK:
              return { ...state, feedback: action.payload };
      
                  // send feedback success
          case SEND_FEEDBACK_SUCCESS:
          NotificationManager.success(Message({id:"feedback.success"}));
          return { ...state, feedback: null };
      
              // send feedback failure
              case SEND_FEEDBACK_FAILURE:
              NotificationManager.error(Message({id:"feedback.failure"}));
              return { ...state, feedback: null };
      
    default: return { ...state };
  }
}
