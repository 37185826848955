/**
 * Todo App Reducer
 */
/* eslint-disable */
import * as _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import React from 'react';
import IntlMessages from '../util/IntlMessages';
import fileDownload from 'react-file-download';

// action types
import {
    GET_USER_DOMAINS,
    GET_USER_DOMAINS_SUCCESS,
    GET_USER_DOMAINS_FAILURE,
    GET_MY_CONSENTS,
    GET_MY_CONSENTS_SUCCESS,
    GET_MY_CONSENTS_FAILURE,
    SELECT_MY_CONSENTS_DOMAIN,
    DOWNLOAD_CONSENTS,
    DOWNLOAD_DATA_CONSENTS,
    DELETE_CONSENT_FOR_DOMAIN,
    DELETE_CONSENT_FOR_DOMAIN_SUCCESS,
    DELETE_CONSENT_FOR_DOMAIN_FAILURE,
    DELETE_DATA_CONSENT_FOR_DOMAIN,
    DELETE_DATA_CONSENT_FOR_DOMAIN_SUCCESS,
    DELETE_DATA_CONSENT_FOR_DOMAIN_FAILURE,
    MODIFY_DATA_CONSENT_FOR_DOMAIN,
    SET_DOMAIN_NAME_FROM_QUERYPRAM

} from '../actions/types';

// initial state
const INIT_STATE = {
    consentDataSet: null,
    dataConsentSet: null,
    observations: null,
    domains: [],
    selectedDomain: null,
    loading: false,
    domainNamefromQuerry:"",
};

const Message = ({ id, domain }) => (
    <span> <IntlMessages id={id} /> {domain}</span>
);
const DeleteMessage = ({ id }) => (
    <span> <IntlMessages id={id} /></span>
);

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get recent state, set the selected domain the sessionStorage, this is used by UI to show the selected domain name
        case GET_MY_CONSENTS:
            return { ...state, loading: true };
        case GET_MY_CONSENTS_SUCCESS:
            return { ...state, consentDataSet: action.payload.data.consentDataSet, dataConsentSet: action.payload.data.dataConsentSet, loading: false };
        case GET_MY_CONSENTS_FAILURE:
            return { ...state, consentDataSet: [], dataConsentSet: [], loading: false };

        case GET_USER_DOMAINS:
            return { ...state, loading: true };
        case GET_USER_DOMAINS_SUCCESS:
            return { ...state, domains: _.map(action.payload.data, 'domain'), loading: false };
        case GET_USER_DOMAINS_FAILURE:
            return { ...state, domains: [], loading: false };
        case SELECT_MY_CONSENTS_DOMAIN:
            
            return { ...state, selectedDomain: action.payload.domain, loading: true };
        case DOWNLOAD_CONSENTS:
            fileDownload(JSON.stringify(state.consentDataSet), 'ConsentSet_' + state.selectedDomain + '.json');
            return { ...state }
        case DOWNLOAD_DATA_CONSENTS:
            fileDownload(JSON.stringify(state.dataConsentSet), 'DataConsentSet_' + state.selectedDomain + '.json');
            return { ...state }
        case DELETE_CONSENT_FOR_DOMAIN:
            return { ...state, loading: true };
        case DELETE_CONSENT_FOR_DOMAIN_SUCCESS:
            return { ...state, domains: _.map(action.payload.data, 'domain'), consentDataSet: null, loading: false, selectedDomain: null, dataConsentSet: null };
        case DELETE_CONSENT_FOR_DOMAIN_FAILURE:
            NotificationManager.error(DeleteMessage({ id: "preferences.deletefailure" }));
            return { ...state, consentDataSet: null, loading: false };

        case DELETE_DATA_CONSENT_FOR_DOMAIN:
            return { ...state, loading: true };
        case DELETE_DATA_CONSENT_FOR_DOMAIN_SUCCESS:
            return { ...state, dataConsentSet: null, loading: false };
        case DELETE_DATA_CONSENT_FOR_DOMAIN_FAILURE:
            NotificationManager.error(DeleteMessage({ id: "preferences.deletefailure" }));
            return { ...state, dataConsentSet: null, loading: false };
        case SET_DOMAIN_NAME_FROM_QUERYPRAM :
            return{...state,domainNamefromQuerry:action.payload}
        default:
            return { ...state };
        // case MODIFY_DATA_CONSENT_FOR_DOMAIN:

        //     var consentDataSet = state.consentDataSet;
        //     consentDataSet[0].consents[action.payload].value=!(consentDataSet[0].consents[action.payload].value)
        //     console.log(action.payload,"from reducer")
        //     return({...state,consentDataSet:consentDataSet})

    }
}
