/**
 * Audit Log Reducer
 */
/* eslint-disable */

import * as _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import React  from 'react';
import IntlMessages from '../util/IntlMessages';

// action types
import {
    GET_AUDIT_LOG,
    AUDIT_LOG_SUCCESS,
    AUDIT_LOG_FAILURE,
    FILTER_AUDIT_LOG_BY_CONSENT,
    FILTER_AUDIT_LOG_BY_DOMAIN,
    FILTER_AUDIT_LOG_BY_TYPE
} from '../actions/types';

// initial state
const INIT_STATE = {
    auditLog:[],
    filteredAuditLog:[],
    domainFilterText: '',
    consentFilterText: '',
    loading:false
};

const Message = ({ id }) => (
    <IntlMessages id={id} />
);
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get AUDIT Log
        case GET_AUDIT_LOG:
            return { ...state, loading:true };
        // Success in getting Audit Log, set the response in the state...
        case AUDIT_LOG_SUCCESS:
            return { ...state, loading:false, auditLog:action.payload.data, filteredAuditLog:action.payload.data };           
        // failure in fetching the audit log data    
        case AUDIT_LOG_FAILURE:
            NotificationManager.error(Message({id:"auditlog.error"}));
            return { ...state, loading: false };             
        
        // filter audit log by domain    
        case FILTER_AUDIT_LOG_BY_DOMAIN:
        if (action.payload === '') {
            // check if the audit log is filtered by consent
            if(state.consentFilterText === ''){
                return { ...state, filteredAuditLog: state.auditLog, domainFilterText:action.payload };
            }
            // if audit log is filtered by consent then return filtered records
            else{
                // reject null consents 
                var notNullDefault = _.reject(state.auditLog, ['consentName', null]);    
                var results=_.filter(notNullDefault,function(item){
                    return item.consentName.toLowerCase().indexOf(state.consentFilterText.toLowerCase())>-1;
                });

                return { ...state, filteredAuditLog: results,domainFilterText:action.payload };
            }
            
        } 
        else {
            var results = [];
            if(state.consentFilterText === ''){
                var notNullDefault = _.reject(state.auditLog, ['domain', null]);   
                results=_.filter(notNullDefault,function(item){
                    return item.domain.toLowerCase().indexOf(action.payload.toLowerCase())>-1;
                });
            }else{
                // reject null domains 
                var notNullDefault = _.reject(state.auditLog, ['domain', null]);    
                results=_.filter(notNullDefault,function(item){
                    return item.domain.toLowerCase().indexOf(action.payload.toLowerCase())>-1;
                });

                // reject null consents 
                var notNullDefault = _.reject(results, ['consentName', null]);    
                results=_.filter(notNullDefault,function(item){
                    return item.consentName.toLowerCase().indexOf(state.consentFilterText.toLowerCase())>-1;
                });
            }
            return { ...state, filteredAuditLog: results, domainFilterText:action.payload }
        }

        // filter audit log by consent    
        case FILTER_AUDIT_LOG_BY_CONSENT:
        if (action.payload === '') {
            // check if the audit log is filtered by domain
            if(state.domainFilterText === ''){
                return { ...state, filteredAuditLog: state.auditLog, consentFilterText:action.payload };
            }
            // if audit log is filtered by domain then return filtered records
            else{
                // reject null domains 
                var notNullDefault = _.reject(state.auditLog, ['domain', null]);    
                var results=_.filter(notNullDefault,function(item){
                    return item.domain.toLowerCase().indexOf(state.domainFilterText.toLowerCase())>-1;
                });

                return { ...state, filteredAuditLog: results,consentFilterText:action.payload };
            }
            
        } 
        else {
            var results = [];
            if(state.domainFilterText === ''){
                // reject null consents 
                var notNullDefault = _.reject(state.auditLog, ['consentName', null]);    

                results=_.filter(notNullDefault,function(item){
                    return item.consentName.toLowerCase().indexOf(action.payload.toLowerCase())>-1;
                });
            }else{
                // reject null consents 
                var notNullDefault = _.reject(state.auditLog, ['consentName', null]);    
                results=_.filter(notNullDefault,function(item){
                    return item.consentName.toLowerCase().indexOf(action.payload.toLowerCase())>-1;
                });

                // reject null domains 
                var notNullDefault = _.reject(results, ['domain', null]);    

                results=_.filter(notNullDefault,function(item){
                    return item.domain.toLowerCase().indexOf(state.domainFilterText.toLowerCase())>-1;
                });
            }
            return { ...state, filteredAuditLog: results, consentFilterText:action.payload }
        }

        case FILTER_AUDIT_LOG_BY_TYPE:
        
        if(action.payload !== null){
            var results = state.auditLog;
            
            if(state.domainFilterText !== ''){
                results=_.filter(results,function(item){
                    return item.domain.toLowerCase().indexOf(state.domainFilterText.toLowerCase())>-1;
                });

                if(state.consentFilterText !==''){
                    results=_.filter(results,function(item){
                        return item.domain.toLowerCase().indexOf(state.consentFilterText.toLowerCase())>-1;
                    });
                }
            }else{
                if(state.consentFilterText !==''){
                    results=_.filter(results,function(item){
                        return item.domain.toLowerCase().indexOf(state.consentFilterText.toLowerCase())>-1;
                    });
                }
            }
            

            if(action.payload.consentGet === false){
                // filter for consentGet logs
                results = _.reject(results, {eventType:'consentGet'});
            }
            
            if(action.payload.consentModified === false){
                results = _.reject(results, {eventType: 'consentAdded'});
                results = _.reject(results, {eventType: 'consentUpdate'});    
            }
            //results = _.filter(results, {})
            return { ...state, loading:false, auditLog:state.auditLog, filteredAuditLog:results };           
        }else{
            return { ...state, loading:false };           
        }
        
        default: return { ...state };

    }
}
