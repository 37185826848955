import React from "react";
import { Icon } from "semantic-ui-react";
import moment from 'moment';
import IntlMessages from "../../../util/IntlMessages"

export const ConsentListCheckBox = (props) => {
    let labeltext=<i className="fa fa-thumbs-up color-green-dark"></i>
   
    if(!props.consent.value){
        
        labeltext=<i className="fa fa-thumbs-down color-red-dark"></i>
    }

    return (
        <>
        <div style={{padding:"0px"}} className="content">
            <ul style={{marginBottom:"10px"}} className="link-list">
                <li style={{marginLeft:"0px"}}><a onClick={()=>props.onCheckBoxToggle(props.consent)} href="javascript:;"><span><IntlMessages id={"consenttitle." + props.consent.name} /></span>{labeltext}</a></li>
                <p style={{paddingBottom:'10px'}} className="half-bottom">
                <IntlMessages id={"consent." + props.consent.name} />
				</p> 
            </ul>
            <span className="small-text-icon font-11 color-night-light">
				<i className="fa fa-pen-square"></i><IntlMessages id="consent.lastedit"/>:
				<a className="theme-color" href="javascript:;"> {moment(props.consent.lastUpdated).format("DD MMMM YYYY, HH:mm:ss ")}</a>
			</span><br></br>
        </div>
        <div className="decoration decoration-margins"></div>
        </>
    )
}


export const DataConsentListCheckBox = (props) => {
    let labeltext=<i className="fa fa-thumbs-up color-green-dark"></i>
   
    if(!props.consent.consent){
        
        labeltext=<i className="fa fa-thumbs-down color-red-dark"></i>
    }
    return (
        <>
            
        <div style={{padding:"0px",marginBottom:"10px"}} className="content">
            <ul style={{marginBottom:"10px"}} className="link-list">
                <li style={{marginLeft:"0px"}}><a onClick={()=>props.onCheckBoxToggle(props.consent)} href="javascript:;"><span>{props.consent.name}</span>{labeltext}</a></li>
                <p style={{paddingBottom:'10px'}} className="half-bottom">
                
				</p> 
            </ul>
   
        </div>
        <div className="decoration decoration-margins"></div>
        </>

        
    )
}


export const ObservationListCheckBox = (props) => {


    return (
        <>

            <div className="consent-item">

                <div className="toggle-button material-switch bottom-15">

                    <label className="material-label" htmlFor={`myonoffswitch3${props.index}`}></label>
                </div>

                <p className="app-contrast-color"><span className="fw-bold"> {props.observation.keyValues.pageName} </span></p>
                <p className="app-contrast-color"> {props.observation.keyValues.event} @ {moment(props.observation.eventTime).format("DD MMMM YYYY HH:mm:ss ")}</p>

            </div>
        </>
    )
    
}
