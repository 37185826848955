/**
 * User Profile Reducer
 */
/* eslint-disable */

import * as _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import fileDownload from 'react-file-download';
import React  from 'react';
import IntlMessages from '../util/IntlMessages';

import AppConfig from '../constants/AppConfig';

// action types
import {
SAVE_CUSTOMER,
SAVE_CUSTOMER_SUCCESS,
SAVE_CUSTOMER_FAILURE
} from '../actions/types';

// initial state
const INIT_STATE = {
    customer:null,
    loading:false,
    createdCustomer:null
};

const Message = ({ id }) => (
    <IntlMessages id={id} />
);

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // Register a customer
        case SAVE_CUSTOMER:
        var customer    = {
            name:action.payload.name,
            requestedDomains : action.payload.requestedDomains,
            primaryEmail : action.payload.primaryEmail,
            address:action.payload.address,
            businessId:action.payload.businessId,
            phone:action.payload.phone,
            countryCode: action.payload.countryCode
        }
            return { ...state, loading:true, customer:customer, createdCustomer:null };
        // success in updating profile
        case SAVE_CUSTOMER_SUCCESS: 
            NotificationManager.success(Message({id:"customer.registrationSuccess"}));
            return { ...state, loading:false, customer:null, createdCustomer:action.payload };
        //failure to update the profile           
        case SAVE_CUSTOMER_FAILURE:
            NotificationManager.error(Message({id:"customer.registrationFailure"}));
            return { ...state, loading: false, customer:null, createdCustomer:null };             
                    
        default: return { ...state };

    }
}
