/**
 * Auth Sagas
 */
import { all, call,  put, takeEvery,select,delay } from 'redux-saga/effects';
import axios from 'axios';
// app config
import AppConfig from '../constants/AppConfig';
import { UserManager } from 'oidc-client';

import {getIdentityAxiosRequest, getAxiosRequest} from "../helpers/helpers"

import {
    signinUserSuccess,
    signinUserFailure,
    socialSigninUserFailure,
    deleteUserSuccess,
    deleteUserFailure,
    sendDeleteUserLinkSuccess,
    sendDeleteUserLinkFailure,
    confirmUserWithPincodeSuccess,
    confirmUserWithPincodeFailure,
    authenticateUserSuccess,
    authenticateUserFailure,
    logOff,
    logOffFailure,
    logOffSuccess
} from '../actions';

var querystring = require('querystring');

export const user = (state) => state;
/**
 * Send Email address to server to get the magic link mailed.
 */

const magicLink = async (model) => {
    await getIdentityAxiosRequest().post(`${AppConfig.identityApiPath}/account/magiclink`, model);
   
}

const confirmMagicLinkPin = async (model) => {
    const request =  await getIdentityAxiosRequest().post(`${AppConfig.identityApiPath}/account/magicpinconfirmation`, model);
    return  await request;   
   
}

const socialLogin = async (model) => {
    const request = await getAxiosRequest().post(`${AppConfig.apiPath}/account/sociallogin`, model)
    return await request;   
}

// const updateLoginTimestamp = async (userToken) => {
//     var config = {};
//     config = {
//         headers: {'Authorization': "bearer " + userToken}
//     };
//     // Update default consents
//     const request = await axios.put(`${AppConfig.apiPath}/user/logintimestamp`, {}, config)
//     return await request;   
// }
const updateLoginTimestamp = async () => {
    // Update default consents
    const request = await getAxiosRequest.put(`${AppConfig.apiPath}/user/logintimestamp`, {})
    return await request;   
}

const deleteProfileLink = async () => {
    
        const request = await getAxiosRequest().get(`${AppConfig.apiPath}/account/delete`)
        return await request;
   
   
}


// generate the link for delete profile to be sent to user.
const deleteProfileFromServer = async (model) => {
        // Get default consents from the server
        const request = await getAxiosRequest().delete(`${AppConfig.apiPath}/account`, {data:model})
        return await request;
}

const authenticate = async () => {
    return await getAxiosRequest().get(`${AppConfig.apiPath}/account/authenticate`);
}

const logoff = async () => {
    return await getIdentityAxiosRequest().post(`${AppConfig.identityApiPath}/account/logoff`, {});
   
}



async function getIdentity()  {
    let manager = new UserManager(AppConfig.identityConfig);
    let promise = Promise.resolve(manager.metadataService.getTokenEndpoint());
    let tokenEndpoint = await promise;
    const config_header = {
      headers: {'Content-Type':'application/x-www-form-urlencoded'}
    }

    var config = {
        client_id: AppConfig.identityConfig.client_id,
        grant_type:AppConfig.identityConfig.grant_type,
        scope:AppConfig.identityConfig.scope,
    }
    return await axios.post(tokenEndpoint, querystring.stringify(config), config_header);
    
}

/**
 * Generate Magic Link
 */
function* sendMagicLink() {
    
    try {
        let model = yield select(user); // <-- get the model from state
        // generate model with user email and userid
        var magicLinkModel = {
            email: model.authUser.email,
            token: model.authUser.token
        }
        // call api to generate magic link
        const response = yield call(magicLink,magicLinkModel);
        yield put(signinUserSuccess(response))     
    } catch (error) {
                // // if the identity token is invalid then try to get a new token and again try sending a magic link.
                if(error.response.status === 401){
                    try{
                        const identityResponse = yield call(getIdentity);
                        
                        let identity_token_expires_on = (new Date().getTime() / 1000) + identityResponse.data["expires_in"];
                        localStorage.setItem('identity_token_expires_on', identity_token_expires_on)
                        localStorage.setItem('identityToken', identityResponse.data.access_token)

                        yield delay(1000);                        
                        let model = yield select(user); // <-- get the model from state
                        // generate model with user email and userid
                        var magicLinkModel = {
                            email: model.authUser.email,
                            token: model.authUser.token
                        }
                        // call api to generate magic link
                        const response = yield call(magicLink,magicLinkModel);
                        yield put(signinUserSuccess(response))     
                       
                    } catch (error) {
                        yield put(signinUserFailure(error.response.data.error));
                    }
                }else{
                    yield put(signinUserFailure(error.response.data.error));
                }
        
      
        
    }
}


/**
 * Confirm User with Pin code received in magic link email.
 */
function* confirmUserWithPin() {
    try {
        let model = yield select(user); // <-- get the model from state
        // generate model with user email and userid
        var magicLinkModel = {
            email: model.authUser.user.user.email,
            token: model.authUser.user.user.token
        }
        
        // call api to generate magic link
        const response = yield call(confirmMagicLinkPin,magicLinkModel);
        yield put(confirmUserWithPincodeSuccess(response.data))
         
           
    } catch (error) {
        yield put(confirmUserWithPincodeFailure(error.response.data.error));
    }
}
/**
 * Social Login
 */
function* socialLoginWithProvider() {
    try {
        let model = yield select(user); // <-- get the model from state
        // if the social provider has given back response.
        if(model.authUser.socialuser){
            // Call the sociallogin api route with social provider response
            const response = yield call(socialLogin,model.authUser.socialuser);
            // If api provides the response with JWT token
            if(response){

                // Set localStorage to indicate successful login
                localStorage.setItem('login', "1");
                
                // Write Cookie and redirect the user to root page, which will redirect the user to welcome page.
                yield put({
                    type: 'LOGIN_SOCIAL_USER_SUCCESS',
                    payload: response.data,
                  });
                  
                  yield call(updateLoginTimestamp);
                  yield call(delay, 2000)

                  
                  
            }
        
        }
        
    } catch (error) {
        
        
       
        if (error.response){
            yield put(socialSigninUserFailure(error.response.data));
        }
        //raise error
        
    }
}

function* sendDeleteProfileLink() {
    try {
        // call api to generate magic link
        const response = yield call(deleteProfileLink);
        yield put(sendDeleteUserLinkSuccess(response))     
    } catch (error) {
        yield put(sendDeleteUserLinkFailure(error.response.data.error));
    }
}

function* deleteProfile() {
    try {

        let model = yield select(user); // <-- get the model from state
        // generate model with user email and userid

        // call api to generate magic link
        
        const response = yield call(deleteProfileFromServer,model.authUser.deleteUser);
        yield put(deleteUserSuccess(response))     
    } catch (error) {
        yield put(deleteUserFailure(error.response.data.error));
    }
}
function* authenticateUser() {
    try {
        
        // call api to generate magic link
        const response = yield call(authenticate);
        yield put(authenticateUserSuccess(response.data))     
    } catch (error) {
        yield put(authenticateUserFailure(''));
    }
}
function* logOffUser() {
    try {
        // call api to generate magic link
        const response = yield call(logoff);
        yield put(logOffSuccess(response))    
        
    } catch (error) {
        // if the identity token is invalid then try to get a new token and again try logoff.
        if(error.response.status ===401){
            try{
                const identityResponse = yield call(getIdentity);

                let identity_token_expires_on = (new Date().getTime() / 1000) + identityResponse.data["expires_in"];
                localStorage.setItem('identity_token_expires_on', identity_token_expires_on);
                localStorage.setItem('identityToken', identityResponse.data.access_token);

                const response = yield call(logoff);
                yield put(logOffSuccess(response)) 
               
            } catch (error) {
                yield put(logOffFailure(''));    
            }

        }else{
            yield put(logOffFailure(''));
        }
    }
}
export const authenticationSagas = [
    takeEvery('LOGIN_USER', sendMagicLink),
    takeEvery('CONFIRM_USER_PINCODE', confirmUserWithPin),
    takeEvery("LOGIN_SOCIAL_USER", socialLoginWithProvider),
    takeEvery('DELETE_USER', deleteProfile),
    takeEvery("SEND_DELETE_PROFILE_LINK", sendDeleteProfileLink),
    takeEvery('AUTHENTICATE_USER', authenticateUser),
    takeEvery('LOGOUT_USER', logOffUser),

]
  


/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...authenticationSagas,
      ])
}

