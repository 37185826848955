/**
 * Todo Sagas
 */
import { all, call, put, takeEvery,select } from 'redux-saga/effects';
import axios from 'axios';
// app config


import {
    getDefaultDomainSuccess,
    getDefaultDomainFailure,
    updateDefaultDomainSuccess,
    updateDefaultDomainFailure,
    updateFirstLoginStatusSuccess,
    updateFirstLoginStatusFailure
} from '../actions';
import {getAxiosRequest} from "../helpers/helpers"


/**
 * Send Domain Request To Servercons
 * The url will be changed to api url later
 */


// Get default consents from the state...
export const defaultConsent = (state) => state.defaultConsentsReducer;

// Get user consents for the default domain
const UserConsents = async (model) => {
    return await getAxiosRequest().get('/consent/default');
}

// Method to update the default consents
const UpdateDefaultConsents = async (model) => {
    return await getAxiosRequest().post('/consent', model);
}


// Method to update the first login flag
const UpdateFirstLoginStatus = async () => {
    return await getAxiosRequest().put('/user/firstlogin', {firstLogin:false});
}


/**
 * Get Default Consents From Server
 */
function* getDomainsFromServer() {
    try {
        const response = yield call(UserConsents);
        yield put(getDefaultDomainSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(getDefaultDomainFailure(error));
    }
}

/**
 * Update Default Consents 
 */
function* updateDefaultConsents() {
    try {
        let model = yield select(defaultConsent); // <-- get the model
        
        var consentSet = [];
        model.consents.forEach(element => {
            var consentUpdateModel = {
                domain:'Default',
                name:element.name,
                value:element.value
            }
            consentSet.push(consentUpdateModel);
        });
        
        
        const response = yield call(UpdateDefaultConsents, consentSet);
        yield put(updateDefaultDomainSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(updateDefaultDomainFailure(error));
    }
}

/**
 * Update Default Consents 
 */
function* updateFirstLogin() {
    try {
        
        
        const response = yield call(UpdateFirstLoginStatus);
        yield put(updateFirstLoginStatusSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(updateFirstLoginStatusFailure(error));
    }
}

/**
* Get Domains
 */

export const defaultDomainSagas = [
    takeEvery('GET_DEFAULT_DOMAIN_CONSENTS', getDomainsFromServer),
    takeEvery("UPDATE_DEFAULT_CONSENTS", updateDefaultConsents),
    takeEvery("UPDATE_FIRST_LOGIN_STATUS", updateFirstLogin),
]
  


/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...defaultDomainSagas,
      ])
}