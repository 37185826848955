import React,{useContext,useState,Component} from "react";
import FullScreenSlider  from "../../components/FullScreenSlider/FullScreenSlider";
import { Button, Loader, Segment, Dimmer, Dropdown, Input, Select } from "semantic-ui-react";
import AuthContext from '../../context/AuthContext'


class  GetSatrtedPage extends Component {
    // let [loading,changeloading]=useState({loading:true})
    // setTimeout(()=>{
    //     changeloading({loading:false})
    // },2000)
    state={
        loading:true
    }
    componentDidMount=()=>{
        this.mounted=true
        setTimeout(()=>{
            if(this.mounted){
                this.setState({
                    loading:false
                })
            }
            
        },1000
            
        )
    }
    componentWillUnmount=()=>{
        this.mounted=false
    }
    

    render(){
        return(
            <>
             <Dimmer active={this.state.loading}>
                            <Loader>Loading</Loader>
             </Dimmer>
                <FullScreenSlider></FullScreenSlider>
            </>
        )
    }
   
}

export default GetSatrtedPage