import React from "react"
import { Button, Loader, Segment, Dimmer, Dropdown, Input, Select } from "semantic-ui-react";

const loader=()=>{
    return(
        <>
          <Dimmer active={true}>
                        <Loader>Loading</Loader>
                    </Dimmer>  
        </>
    )
}
export default loader