import React, { Component } from "react";
import { connect } from "react-redux"
import { NavLink, HashRouter as Router, Route } from "react-router-dom"
import IntlMessages from "../../../../util/IntlMessages"
import FeedbackAlert from "../../../../components/FeedbackAlert/FeedbackAlert";
import { sendFeedback } from "../../../../actions";
import { NotificationManager } from 'react-notifications';

class FooterMenu extends Component {
    state = {
        popupFeedbackVissible: false
    }
    
    bottomFeedbackToggle = () => {
        window.scrollTo(0,0)
        this.setState((prevstate) => {

            return (
                {
                    popupFeedbackVissible: !prevstate.popupFeedbackVissible
                }
            )
        })
    }
    sendFeedback = (message) => {
        if (message == null || message == "") {
            NotificationManager.error('cannot send an empty message ');
        } else {
            this.props.sendFeedback(message)
            this.bottomFeedbackToggle()
        }

    }

    render() {
        
        let bottomFeedbackClasses = ""
        let hider = ""
        if (this.state.popupFeedbackVissible) {
            bottomFeedbackClasses = "feedback-from-bottom bottom-feedback-active";
            hider = "new-hider"
        }
        else {
            bottomFeedbackClasses = "feedback-from-bottom"

        }
       
        let [profile, mydata, dashboard, defaults] = ["active-menu", "active-menu", "active-menu", "active-menu"]
        switch (this.props.active.pathname) {
            case "/profile":
                profile = "active-menu theme-color"
                break
            case "/mydata":
                mydata = "active-menu theme-color"
                break
            case "/":
                dashboard = "active-menu theme-color"
                break
            case "/mydefaults":
                defaults = "active-menu theme-color"
                break
            default:
                break

        }
        return (
            <>
                <div onClick={this.bottomFeedbackToggle} className={hider}></div>
                <div id="footer-menu" className="footer-menu-5-icons app-background display-toggle">
                    <NavLink to="/profile" className={profile}><i className='fa fa-id-card app-contrast-color'></i><span className="app-contrast-color" style={{ padding: "4px 0px 0px 0px" }}><IntlMessages id="sidebar.profile" /></span></NavLink>
                    <NavLink to="/mydata" className={mydata}><i className='fa fa-info-circle app-contrast-color'></i><span className="app-contrast-color" style={{ padding: "4px 0px 0px 0px" }}><IntlMessages id="sidebar.datasetconsents" /></span></NavLink>
                    <NavLink to="/" className={dashboard}><i className='fa fa-tablet-alt app-contrast-color'></i><span className="app-contrast-color" style={{ padding: "4px 0px 0px 0px" }}><IntlMessages id="sidebar.dashboard" /></span></NavLink>
                    <NavLink to="/mydefaults" className={defaults}><i className='fa fa-pen-square app-contrast-color'></i><span className="app-contrast-color" style={{ padding: "4px 0px 0px 0px" }}><IntlMessages id="sidebar.defaultconsents" /></span></NavLink>
                    <NavLink to={this.props.active.pathname} onClick={this.bottomFeedbackToggle} className="active-menu"><i className="fa fa-comment-alt app-contrast-color"></i><span className="app-contrast-color" style={{ padding: "4px 0px 0px 0px" }}><IntlMessages id="component.feedback" /></span></NavLink>
                    <div className="clear"></div>
                </div>
                <div className={bottomFeedbackClasses}>
                    <FeedbackAlert toggle={this.bottomFeedbackToggle} SendFeedback={this.sendFeedback}></FeedbackAlert>
                </div>
            </>

        )
    }
}


const mapStateToProps = ({ settings }) => {
    return settings;
};

export default connect(mapStateToProps, {
    sendFeedback
})(FooterMenu);