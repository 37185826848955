import React from 'react';
import Loadable from 'react-loadable';
// import Loader from 'react-loader-spinner';
import {Dimmer,Loader} from "semantic-ui-react"
import "../../App.css"



const LoaderComponent = () => {
	return (
		<>
			<Dimmer active={true}>
				<Loader>Loading</Loader>
			</Dimmer>
		</>
	)
}
export const AsyncHomepage = Loadable({
	loader: () => import("../../pages/HomePage/HomePage"),
	loading: () => <LoaderComponent/>,
});

export const AsyncProfilePage = Loadable({
	loader: () => import("../../pages/ProfilePage/ProfilePage"),
	loading: () => <LoaderComponent/>,
});

export const AsyncRegisterPage = Loadable({
	loader: () => import("../../pages/RegisterationPage/RegisterationPage"),
	loading: () => <LoaderComponent/>,
});

export const AsyncMydataPage = Loadable({
	loader: () => import("../../pages/MydataPage/MydataPage"),
	loading: () => <LoaderComponent/>,
});

export const AsyncTermsCondition = Loadable({
	loader: () => import("../../components/Terms&Conditions/WithoutHeaderFooter/Terms&Conditions"),
	loading: () => <LoaderComponent/>,
});
export const AsyncTermsConditionWithHeader = Loadable({
	loader: () => import("../../components/Terms&Conditions/WithHeaderFooter/Terms&Conditions"),
	loading: () => <LoaderComponent/>,
});
export const AsyncMyDefaults = Loadable({
	loader: () => import("../../pages/MyDefaults/MyDefaults"),
	loading: () => <LoaderComponent/>,
});
export const AsyncAuditLog = Loadable({
	loader: () => import("../../pages/AuditLogPage/AuditLogPage"),
	loading: () => <LoaderComponent/>,
});
export const AsyncAddSegemnt = Loadable({
	loader: () => import("../../pages/AddSegmentPage/AddSegmentPage"),
	loading: () => <LoaderComponent/>,
});
export const AsyncDomainConsent = Loadable({
	loader: () => import("../../pages/DomainConsentPage/DomainConsentPage"),
	loading: () => <LoaderComponent/>,
});
export const AsyncMergeDefaults = Loadable({
	loader: () => import("../../pages/MergeDefaultsPage/index"),
	loading: () => <LoaderComponent/>,
});