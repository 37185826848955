/**
 * User Profile Sagas
 */
import { all, call, fork, put, takeEvery,select } from 'redux-saga/effects';
import axios from 'axios';
import * as _ from 'lodash'
import {getAxiosRequest, getIdentityAxiosRequest} from '../helpers/helpers'

import {
    GET_USER_PROFILE,
    UPDATE_USER_PROFILE,
    ADD_SOCIAL_LOGIN_TO_PROFILE,
    DELETE_SOCIAL_LOGIN_FROM_PROFILE,
    DOWNLOAD_PROFILE, 
    MERGE_PROFILE,
    GET_CURRENT_SYSTEM_ROLES,
    INVITE_FRIEND
} from '../actions/types';

import {
    getUserProfileSuccess,
    getUserProfileFailure,
    updateUserProfileSuccess,
    updateUserProfileFailure,
    addSocialLoginSuccess,
    addSocialLoginFailure,
    deleteSocialLoginSuccess,
    deleteSocialLoginFailure,
    refreshUserProfile,
    downloadProfileSuccess,
    downloadProfileFailure,
    mergeProfileSuccess,
    mergeProfileFailure,
    getCurrentRolesSuccess,
    getCurrentRolesFailure,
    inviteFriendSuccess, 
    inviteFriendFailure
} from '../actions/index';


/**
 * Send Domain Request To Servercons
 * The url will be changed to api url later
 */


// Get default consents from the state...
export const userProfile = (state) => state.userProfile;

// Get user consents for the default domain
const UserProfile = async (model) => {
    return await getAxiosRequest().get('/user');
}

// Get user's current roles
const CurrentRoles = async (model) => {
    return await getAxiosRequest().get('/user/roles');
}

// Method to update the default consents
const UpdateProfile = async (model) => {
    return await getAxiosRequest().put('/user', model);
}


// Method to add social login to profile
const AddSocialLogin = async (model) => {
    return await getIdentityAxiosRequest().post('/account/login', model);

}


// Method to delete social login from profile
const DeleteSocialLogin = async (model) => {
    return await getIdentityAxiosRequest().put('/account/login', model);
}


const DownloadProfile = async (model) => {
    return await getAxiosRequest().get('/user/download');
}

// Method to merge observed profile
const MergeProfile = async (model) => {
    var observed = {
        "Id":model
    }

    return await getAxiosRequest().post('/user/merge', observed);
}

const InviteFriend = async (model) => {
    return await getAxiosRequest().post('/account/invite', model);
}

/**
 * Get Default Consents From Server
 */
function* getUserProfile() {
    try {
        const response = yield call(UserProfile);
        yield put(getUserProfileSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(getUserProfileFailure(error));
    }
}

/**
 * Get Current Roles from server
 */
function* getCurrentRoles() {
    try {
        const response = yield call(CurrentRoles);
        yield put(getCurrentRolesSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(getCurrentRolesFailure(error));
    }
}
/**
 * Update Default Consents 
 */
function* updateUserProfile() {
    try {
        let model = yield select(userProfile); // <-- get the model
        
        var consentSet = [];
        
        
        const response = yield call(UpdateProfile, model.userProfile);
        yield put(updateUserProfileSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(updateUserProfileFailure(error));
    }
}

/**
 * Add Social Login to profile 
 */
function* addSocialLoginToProfile() {
    try {
        let model = yield select(userProfile); // <-- get the model
        const response = yield call(AddSocialLogin, model.socialLogin);
        yield put(addSocialLoginSuccess(response));
        const refreshResponse = yield call(UserProfile);
        yield put(refreshUserProfile(refreshResponse));

    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(addSocialLoginFailure(error.response.data.error));
    }
}

/**
 * Delete Social Login from profile 
 */
function* deleteSocialLoginFromProfile() {
    try {
        let model = yield select(userProfile); // <-- get the model
        const response = yield call(DeleteSocialLogin, model.loginToDelete);
        yield put(deleteSocialLoginSuccess(response));
        const refreshResponse = yield call(UserProfile);
        yield put(refreshUserProfile(refreshResponse));

    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(deleteSocialLoginFailure(error.response.data.error));
    }
}

/**
 * GDonwload User Profile
 */
function* downloadUserProfile() {
    try {
        const response = yield call(DownloadProfile);
        yield put(downloadProfileSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(downloadProfileFailure(error.response.data.error));
    }
}


/**
 * Nerge User Profile
 */
function* mergeUserProfile() {
    try {
        let model = yield select(userProfile); // <-- get the model

        const response = yield call(MergeProfile, model.observedProfile);
        yield put(mergeProfileSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(mergeProfileFailure(error.response.data.error));
    }
}

/**
 * Invite a friend
 */
function* inviteFriend() {
    try {
        let model = yield select(userProfile); // <-- get the model
        var inviteModel = {
            "email": model.friendEmail,
          }
        const response = yield call(InviteFriend, inviteModel);
        yield put(inviteFriendSuccess(response));
    } catch (error) {
        if(error.response &&error.response.status===401){
            return null
        }
        yield put(inviteFriendFailure(error.response.data.error));
    }
}

export const userProfileSagas = [
    takeEvery('GET_USER_PROFILE', getUserProfile),
    takeEvery("UPDATE_USER_PROFILE", updateUserProfile),
    takeEvery("ADD_SOCIAL_LOGIN_TO_PROFILE", addSocialLoginToProfile),
    takeEvery("DELETE_SOCIAL_LOGIN_FROM_PROFILE", deleteSocialLoginFromProfile),
    takeEvery("DOWNLOAD_PROFILE", downloadUserProfile),
    takeEvery("MERGE_PROFILE", mergeUserProfile),
    takeEvery("GET_CURRENT_SYSTEM_ROLES", getCurrentRoles),
    takeEvery("INVITE_FRIEND", inviteFriend)
]
  


/**
 * Default Consents Root Saga
 */
export default function* rootSaga() {
    yield all([
        ...userProfileSagas,
      ])
}