/**
 * Todo App Reducer
 */
/* eslint-disable */

// todo data
import consents from '../assets/data/manageconsents/consents';
import * as _ from 'lodash';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import React  from 'react';
import IntlMessages from '../util/IntlMessages';

// action types
import {
    GET_DOMAIN_CONSENTS,
    GET_DOMAINS,
    ON_SELECT_DOMAIN,
    ON_CHECK_BOX_TOGGLE_CONSENT_ITEM,
    SEARCH_CONSENT,
    SEARCH_DOMAIN,
    GET_DOMAINS_SUCCESS,
    GET_DOMAINS_FAILURE,
    UPDATE_DOMAIN_SEARCH,
    UPDATE_CONSENT_SEARCH,
    GET_CURRENT,
    UPDATE_SELECTED_DOMAIN_CONSENTS,
    UPDATE_SELECTED_DOMAIN_CONSENTS_SUCCESS,
    UPDATE_SELECTED_DOMAIN_CONSENTS_FAILURE,
    GET_PREFERENCES_DATA_CONSENTS,
    GET_PREFERENCES_DATA_CONSENTS_SUCCESS,
    GET_PREFERENCES_DATA_CONSENTS_FAILURE,
    UPDATE_PREFERENCES_DATA_CONSENT,
    UPDATE_PREFERENCES_DATA_CONSENT_SUCCESS,
    UPDATE_PREFERENCES_DATA_CONSENT_FAILURE,
    GET_OBSERVATIONS,
    GET_OBSERVATIONS_SUCCESS,
    GET_OBSERVATIONS_FAILURE,
    MODIFY_DATA_CONSENT_FOR_DOMAIN


} from '../actions/types';

// initial state
const INIT_STATE = {
    domains: [],
    consents:[],
    dataconsents:[],
    observations:null,
    selectedDataConsent:null,
    activeDomainFilter: 0,
    activeConsentFilter: 0,
    selectedDomain: '',
    loading: false,
    showMessage: false,
    selectedConsents: 0,
    filteredConsents:0,
    searchDomain: '',
    searchConsent: ''
};

    const Message = ({ id ,domain }) => (
        <span> <IntlMessages id={id} /> {domain}</span>
    );

    const DeleteMessage = ({ id}) => (
        <span> <IntlMessages id={id} /></span>
    );
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        // get recent state, set the selected domain the sessionStorage, this is used by UI to show the selected domain name
        case GET_CURRENT:
            if(state.selectedDomain !=''){
                sessionStorage.setItem('domain', state.selectedDomain);
            }
            return { ...state };
        // GET List of domains 
        case GET_DOMAINS:
            return { ...state, domains:state.domains, loading:true };
        // Successin getting data from api for all preference (Domains other than default domain)
        case GET_DOMAINS_SUCCESS:
        if(sessionStorage.getItem('domain')!== null && sessionStorage.getItem('domain')!== ''){
            return Object.assign({}, state, {
                domains:_.map(action.payload.data, 'domain'),
                selectedDomain: sessionStorage.getItem('domain'), 
                selectedConsents:_.filter(action.payload.data, {domain:sessionStorage.getItem('domain')})[0].consents,
                consents:action.payload.data, 
                loading:false
            })

        }else{
            return { ...state, domains:_.map(action.payload.data, 'domain'), consents:action.payload.data, loading:false, };           
        }    
        
            
        // Failure in getting the data from the api    
        case GET_DOMAINS_FAILURE:
            NotificationManager.error(Message({id:"preferences.loadDomainFailure"}));
            return { ...state, loading: false };             
        // Cases for updating selected domain consents
        case UPDATE_SELECTED_DOMAIN_CONSENTS:
            
            return { ...state, loading:true, consents:state.consents, selectedConsents:state.selectedConsents, selectedDomain:state.selectedDomain };
        // Success in updating the consents for the selected domain   
        case UPDATE_SELECTED_DOMAIN_CONSENTS_SUCCESS:
            NotificationManager.success(Message({id:"preference.updateSuccess", domain:state.selectedDomain}));
            return { ...state, loading:false };
        // failure in updating the consents for the selected domain           
        case UPDATE_SELECTED_DOMAIN_CONSENTS_FAILURE:
            NotificationManager.error(Message({id:"preference.updateFailure", domain:state.selectedDomain}));
            return { ...state, loading: false };             

        // update domain search
        case UPDATE_DOMAIN_SEARCH:
            return { ...state, searchDomain: action.payload };
        // GET consents for the selected domain     
        case GET_DOMAIN_CONSENTS:
            return { ...state, selectedDomain: state.selectedDomain, selectedConsents:state.selectedConsents }
            
        // search domain
        case SEARCH_DOMAIN:
            if (action.payload === '') {
                return { ...state, domains: _.map(state.consents, 'domain') };
            } else {
                const domains = state.domains.filter((domain) =>
                    domain.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
                return { ...state, domains: domains }
            }

        // User selects a domain in the UI, set the session storage with selected domain name, this is used by the UI to show selected domain name    
        case ON_SELECT_DOMAIN:
            
        if (action.payload.domain === '') {
            return {};
            sessionStorage.clear();
        } else {
            
            sessionStorage.setItem('domain', action.payload.domain);
            return Object.assign({}, state, {
                selectedDomain: action.payload.domain, 
                selectedConsents:_.filter(state.consents, {domain:action.payload.domain})[0].consents

            })
            // return(
            //     {
            //         ...state,selectedDomain: action.payload.domain
            //     }
            // )
        }
        // Search consents of the selected domain 
        case SEARCH_CONSENT:
          
        if (action.payload === '') {
            return { ...state, selectedConsents: _.filter(state.consents, {domain:state.selectedDomain})[0].consents };
        } else {
            const filtered = state.selectedConsents.filter((item) =>
                item.name.toLowerCase().indexOf(action.payload.toLowerCase()) > -1);
            return { ...state, selectedConsents: filtered }
        }

        // update consent search
        case UPDATE_CONSENT_SEARCH:
        return { ...state, searchConsent: action.payload };
        // User changes the state of the consent item of the selected domain 
        case ON_CHECK_BOX_TOGGLE_CONSENT_ITEM:
        // Get current datetime to update
        var dateTime = new Date();
        //dateTime = moment(dateTime).format("YYYY-MM-DD HH:mm:ss.SSS'Z'");
        if (action.payload === null) {
            return { ...state, selectedConsents: _.filter(state.consents, {domain:state.selectedDomain})[0].consents };
        } else {
            const filtered = _.filter(state.selectedConsents, {name:action.payload.name});
            filtered[0].value = !filtered[0].value;
            filtered[0].lastUpdated = dateTime.toISOString(); 
            return Object.assign({}, state, {
                selectedDomain: state.selectedDomain, 
                selectedConsents:state.selectedConsents
            })
        }
        case GET_PREFERENCES_DATA_CONSENTS:
        return { ...state, loading:true };
        case GET_PREFERENCES_DATA_CONSENTS_SUCCESS:
        return { ...state, dataconsents:action.payload.data, loading:false };
        case GET_PREFERENCES_DATA_CONSENTS_FAILURE:
        return { ...state, dataconsents:[], loading:false };
        case UPDATE_PREFERENCES_DATA_CONSENT:
            
        var selectedDataConsent = {
            "ipAddress":"",
            "domain": action.payload.domain,
            "id": action.payload.id,
            "consent": !action.payload.consent,
            "name": action.payload.name
        }
        let dataSet=state.dataconsents;
        let currentDataSetIndex=_.findIndex(dataSet.dataConsentSet,(dataitem)=>{
            return dataitem.name==action.payload.name
        })
        dataSet.dataConsentSet[currentDataSetIndex]=selectedDataConsent
        
        return { ...state,dataconsentSet:dataSet,selectedDataConsent:selectedDataConsent};
        case UPDATE_PREFERENCES_DATA_CONSENT_SUCCESS:
        return { ...state, selectedDataConsent:null, loading:false };
        case UPDATE_PREFERENCES_DATA_CONSENT_FAILURE:
        return { ...state, selectedDataConsent:null, loading:false };
        case GET_OBSERVATIONS:
        return { ...state, loading:true };
        case GET_OBSERVATIONS_SUCCESS:
        var observations = [];
        if(action.payload.data){
            observations = action.payload.data
        }
        return { ...state, observations:observations,loading:false };
        case GET_OBSERVATIONS_FAILURE:
        return { ...state, observations:[], loading:false };
        // case MODIFY_DATA_CONSENT_FOR_DOMAIN:
            
        //         var consentDataSet = state.consents;
        //         consentDataSet[0].consents[action.payload].value=!(consentDataSet[0].consents[action.payload].value)
        //         console.log(action.payload,"from reducer")
        //         return({...state,consentDataSet:consentDataSet})
    

        default: 
        if(state.selectedDomain ===''){
            sessionStorage.clear();
        }

        return { ...state };

    }
}
