/**
 * App Reducers
 */
import { combineReducers } from 'redux';

import authUserReducer from './AuthUserReducer';
import settings from './settings';
import userProfileReducer from './UserProfileReducer'
import customerReducer from './CustomerRegistrationReducer'
import mydataReducer from "../reducers/MydataReducer";
import manageConsentsReducer from '../reducers/ManageConsentsReducer'
import defaultConsentsReducer from './DefaultConsentsReducer'
import auditLogReducer from './AuditLogReducer'
import segmentReducer from './SegmentDashboardReducer';
import magicLinkConfirmationReducer from './MagicLinkReducer'

const reducers = combineReducers({
  settings,
  authUser: authUserReducer,
  userProfile: userProfileReducer,
  defaultConsentsReducer,
  customerReducer,
  mydataReducer,
  magicLinkUser:magicLinkConfirmationReducer,
  manageConsentsReducer,
  auditLog:auditLogReducer,
  userSegments: segmentReducer,
  
  
});

export default reducers;
