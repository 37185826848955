/**
 * Auth User Reducers
 */
import { NotificationManager } from 'react-notifications';
// app config
import React from 'react';


import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    LOGIN_SOCIAL_USER,
    LOGIN_SOCIAL_USER_SUCCESS,
    LOGIN_SOCIAL_USER_FAILURE,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    SEND_DELETE_PROFILE_LINK,
    SEND_DELETE_PROFILE_LINK_SUCCESS,
    SEND_DELETE_PROFILE_LINK_FAILURE,
    CONFIRM_USER_PINCODE,
    CONFIRM_USER_PINCODE_SUCCESS,
    CONFIRM_USER_PINCODE_FAILURE,
    RESET_LOGIN,
    AUTHENTICATE_USER,
    AUTHENTICATE_USER_SUCCESS,
    AUTHENTICATE_USER_FAILURE,
    CHANGE_MERGE_STATUS
} from '../actions/types';


// app config
import AppConfig from '../constants/AppConfig';
import IntlMessages from '../util/IntlMessages';

/**
 * initial auth user
 */

//get the details from the cookie
const cookieData = JSON.parse(localStorage.getItem('gravitoUid'));

const INIT_STATE = {
    user: null,
    email: '',
    loading: true,
    emailSent: false,
    error: '',
    socialuser: null,  // This will be used for the social login
    deleteUser: null,
    userDeleted: false,
    serverState: null,
    role:null,
    merge:false,
    firstLogin:false
};

const Message = ({ id }) => (
    <IntlMessages id={id} />
);

export default (state = INIT_STATE, action) => {
   
    // if the GravitoUi Cookie exists then get the emails and JWT Token from the Cookie    
    if (cookieData) {
        state.user = { email: cookieData.email, token: cookieData.token, language: cookieData.language }
    }
    switch (action.type) {
        // USER LOGIN with the magic link request
        case LOGIN_USER:
            if (action.payload === '') {
                return { ...state, error: 'Please enter a valid email address' };
            } else {
                return { ...state, loading: true, email: action.payload.email.email, token: action.payload.email.token, emailSent: false };
            }
        // MAGIC LINK Successfully generated and sent to the given email address
        case LOGIN_USER_SUCCESS:
            NotificationManager.success('Check your email for confirmation pin to access your Gravito profile.');
            return { ...state, loading: false, email: action.payload, emailSent: true };
        // FAILURE to generate magic link
        case LOGIN_USER_FAILURE:
            NotificationManager.error("We could not login you, please try again later. ");
            return { ...state, loading: false };
        // USER Chooses to login with social login facebook or google for now...
        case LOGIN_SOCIAL_USER:
            if (action.payload === '') {
                return { ...state, error: 'Sign in with your social login has failed' };
            } else {
                return { ...state, loading: true, socialuser: action.payload, emailSent: false };
            }
        // USER logs in successfully with the social login 
        case LOGIN_SOCIAL_USER_SUCCESS:
            // if the jwt token is received successfully then write that to a cookie.
            const expires = new Date()
            expires.setDate(expires.getDate() + 100);
            let cookiedata = {
                email: action.payload.email,
                userid: action.payload.id,
                token: action.payload.jwtToken,
                roles: action.payload.roles,
                language: action.payload.language
            }


            localStorage.setItem('Roles', JSON.stringify(action.payload.roles));
            localStorage.setItem('firstLogin', action.payload.firstLogin);
            setTimeout(() => {
                if(action.payload.firstLogin){
                    window.location = `${AppConfig.appUrl}#/mydefaults`
                }
                else{
                    window.location = `${AppConfig.appUrl}`
                }
            }, 1000);
            return { ...state, loading: false, email: action.payload };
        // Failure to login with social profile      
        case LOGIN_SOCIAL_USER_FAILURE:
            NotificationManager.error("We could not login you, please try again later.");
            return { ...state, loading: false };

        // case LOGOUT_USER:
        //     return { ...state };

        // case LOGOUT_USER_SUCCESS:
        //     return { ...state, user: null };

        // case LOGOUT_USER_FAILURE:
        //     return { ...state };

        case SEND_DELETE_PROFILE_LINK:

            return { ...state, loading: true, userDeleted: true };
        case SEND_DELETE_PROFILE_LINK_SUCCESS:
            NotificationManager.success('Thank you for using Gravito. A Confirmation email has been sent to you.');
            return { ...state, loading: false };

        case SEND_DELETE_PROFILE_LINK_FAILURE:
            sessionStorage.removeItem('delete');
            NotificationManager.error('Oops, we could not send a confirmation email, please try again later.');
            return { ...state, loading: false };

        case DELETE_USER:
            if (action.payload === '') {
                NotificationManager.error('Oops, we could not delete the profile.');
                return { ...state, deleteUser: null, loading: false };

            } else {
                return { ...state, deleteUser: action.payload, loading: true };
            }

        case DELETE_USER_SUCCESS:
            NotificationManager.success('Your profile has been deleted successfully. Thank you for using Gravito.');
            //clear cookie, localstorage and sessionstorage.
            
            sessionStorage.clear();
            localStorage.clear();

            setTimeout(() => {
                window.location = `${AppConfig.appUrl}`
            }, 2000);
            return { ...state, deleteUser: null, loading: false };
        case DELETE_USER_FAILURE:
            NotificationManager.error(action.payload);
            setTimeout(() => {
                window.location = `${AppConfig.appUrl}`
            }, 2000);
            return { ...state, deleteUser: null, loading: false };

        case CONFIRM_USER_PINCODE:
            if (action.payload === '') {

                return { ...state, error: 'The Magic Link is invalid' };
            } else {
                return { ...state, loading: true, user: action.payload };
            }
        // Success in getting the JWTToken from the api
        case CONFIRM_USER_PINCODE_SUCCESS:
            
            // if the jwt token is received successfully then write that to a cookie.
            const cookieExpires = new Date()
            cookieExpires.setDate(cookieExpires.getDate() + 100);
            let userCookieData = {
                email: action.payload.email,
                userid: action.payload.id,
                token: action.payload.jwtToken,
                roles: action.payload.roles,
                language: action.payload.language
            }

            localStorage.setItem('Roles', JSON.stringify(action.payload.roles))
            localStorage.setItem('firstLogin', action.payload.firstLogin);
            localStorage.setItem("merge",false)

            setTimeout(() => {
                if(action.payload.firstLogin){
                    window.location = `${AppConfig.appUrl}#/mydefaults`
                }
                else{
                    window.location = `${AppConfig.appUrl}`
                }
                
            }, 1000);
           
            return { ...state, token: action.payload, firstLogin: action.payload.firstLogin,role:action.payload.roles };

        // Failure in getting the JWT token
        case CONFIRM_USER_PINCODE_FAILURE:
            NotificationManager.error(Message({ id: "magiclink.error" }));
            
            return { ...state, loading: false, error: 1 };
        case RESET_LOGIN:
            return { ...state, emailSent: false };
        case AUTHENTICATE_USER:
           
            return { ...state, loading: true };
        
        case AUTHENTICATE_USER_SUCCESS:

            localStorage.setItem('Roles', JSON.stringify(action.payload.roles));
            localStorage.setItem('firstLogin', action.payload.firstLogin);
          
            
            return { ...state, loading: false, serverState: true,role:action.payload.roles };

        case AUTHENTICATE_USER_FAILURE:
            // localStorage.clear();
            return { ...state, loading: false, serverState: false };
        case LOGOUT_USER:
            return { ...state };

        case LOGOUT_USER_SUCCESS:
            
            sessionStorage.clear();
            localStorage.clear();
            setTimeout(() => {
                window.location = `${AppConfig.appUrl}`
            }, 1000);
            
            return { ...state, user: null,serverState:false };

        case LOGOUT_USER_FAILURE:
            return { ...state };
        case CHANGE_MERGE_STATUS:
            localStorage.setItem("merge","false")
            return{...state, merge:action.payload}

        default: return { ...state, loading: false };
    }
}
