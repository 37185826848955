import React,{useState} from "react";
import { TextArea,Button } from "semantic-ui-react"
import IntlMessages from "../../util/IntlMessages";
import { injectIntl, intlShape } from 'react-intl';
const FeedbackAlert = (props) => {
    
    const [message,changemessage]=useState({
        feedbackmessage:"",
        
        
    })
    const onmessagechange=(e)=>{
        let changedmessage=e.target.value
        
        changemessage({feedbackmessage:changedmessage})
        
    }
    const placeholder = props.intl.formatMessage({id: 'feedback.message'});
    
    return (
        <>
            <div style={{ padding: "8px" }}>
                <h3 className="ultrabold color-main"><IntlMessages id="component.feedback" /></h3>
                <div onClick={props.toggle} className="feedback-hide">
                    <i className="fa fa-times"></i>
                </div>
                <div className="deco-4"></div>
                <TextArea rows={5} placeholder={placeholder}
                value={message.feedbackmessage} onChange={(e)=>{onmessagechange(e)}}>
                   
                </TextArea>
                <div className="deco-4"></div>
                <button  className="button shadow-medium button-round bg-theme-color right-float"
                onClick={()=>{props.SendFeedback(message.feedbackmessage)}}
                >
                <i style={{marginRight:"10px"}} className="fa fa-paper-plane"></i>
                Send</button>
            </div>
        </>
    )
}
export default injectIntl(FeedbackAlert) 