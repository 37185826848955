import React, { Component } from "react";
import { connect } from "react-redux";
import { Dimmer, Card, Loader, Button } from "semantic-ui-react"
import IntlMessages from "../../util/IntlMessages";
import DomainSearch from "./MydataComponents/DomainSearch";
import ConsentSearch from "./MydataComponents/ConsentSearch";
import Consent from "./MydataComponents/Consent";
import * as qs from "query-string";
import * as _ from "lodash";
import {
    modifyDataConsentForDomain,
    fetchSelectedDomainConsents,
    getAllConsents,
    onCheckBoxToggleConsentItem,
    selectAllConsents,
    getUnselectedAllConsents,
    onLabelSelect,
    onLabelMenuItemSelect,
    onSearchConsent,
    updateConsentSearch,
    updateSelectedDomainConsents,

    updatePreferencesDataConsent,
    deleteDomainConsents
} from "../../actions"


class MydataPage extends Component {
    
    componentDidMount=()=>{
        
        
        window.scrollTo(0,0)

    }
    onCheckboxClick = (consent) => {
        this.props.onCheckBoxToggleConsentItem(consent)
    }
    componentWillMount() {
        this.props.getAllConsents();
        this.props.fetchSelectedDomainConsents();
    }
    savedomainConsent = () => {
        
        this.props.updateSelectedDomainConsents()
    }
    updateSearch =(e)=> {
        this.props.updateConsentSearch(e.target.value);
        this.props.onSearchConsent(e.target.value);
    }
    render() {
        let domainName
        
        var fromUrl= this.props.location.pathname=="/app/domainconsents"
        if(fromUrl==true){
            
            domainName=qs.parse(this.props.location.search)
            let isDomainListed=_.includes(this.props.manageConsentsReducer.domains,domainName.domain)
            domainName=isDomainListed?domainName:null
        }
        let showSearchbar=false
        const { consents, selectedConsents, labels, selectedDomain, classes,theme,searchConsent,loading, dataconsents, observations } = this.props.manageConsentsReducer
        
        if(selectedDomain && selectedDomain!=""){
            showSearchbar=true
        }
        
        return (
            <>

                <div className="profile-div">
                    <Dimmer active={loading}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <div className=" margin-auto card-custom">
                     
                        <span className="select-domain">
                            <IntlMessages id="component.mydatadashboard" />  for {' '}
                            <DomainSearch domainName={domainName}></DomainSearch>
                        </span>
                        <div>
                        <ConsentSearch show={showSearchbar} consent={searchConsent} onchange={this.updateSearch} ></ConsentSearch>
                        
                        </div>
                        <Consent data={this.props.manageConsentsReducer} isRedirected={fromUrl} onCheckBoxToggleConsentItem={this.onCheckboxClick} modifyDataConsentForDomain={this.props.updatePreferencesDataConsent} ></Consent>
                        <div></div>
                    </div>
                    <Button className="floating-btn shadow-medium color-white bg-theme-color" circular icon="save" onClick={this.savedomainConsent}></Button>
                </div>

            </>
        )
    }
}
const mapStateToProps = ({ manageConsentsReducer  }) => {
    return{manageConsentsReducer} ;
};


export default connect(mapStateToProps, {
    fetchSelectedDomainConsents,
    getAllConsents,
    onCheckBoxToggleConsentItem,
    selectAllConsents,
    onSearchConsent,
    updateConsentSearch,
    updateSelectedDomainConsents,

    updatePreferencesDataConsent,
    deleteDomainConsents,
    modifyDataConsentForDomain,

})(MydataPage)
