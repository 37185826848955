import React, { Component } from "react";
import { Button, Loader, Segment, Dimmer } from "semantic-ui-react";
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppConfig from "../../constants/AppConfig";
import { NavLink } from "react-router-dom";
import { NotificationManager } from 'react-notifications';

import { connect } from 'react-redux';
import {
    signin,
    confirmUserWithPincode,
    resetLogin,
    signinUserSuccess,
    signinUserFailure,
    socialSignIn,
    socialSigninUserSuccess,
    socialSigninUserFailure,
    authenticateUser
} from "../../actions";

const justifyCenter = {
    display: "flex",
    justifyContent: "center",
    
};
class Login extends Component {
 state = {
        email: '',
        token: '',
        open: false,
        alreadyHavePin: false,
    }

    isEmailValid = false;
    isTokenValid = false;
    componentDidMount() {
        this.props.authenticateUser();
    }
 
    onUserLogin = () => {
        if (this.state.email !== '' && this.isEmailValid) {
            localStorage.setItem('user_id', this.state.email);
            // this.setState({email:""})
            this.props.signin(this.state, this.props.history);
        }
        else {
            NotificationManager.error('Please enter a valid email address');
        }

    }
    onEmailChange(event) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gmi;
        this.setState({ email: event.target.value, open: true });
        if (re.test(event.target.value)) {
            this.isEmailValid = true;
            return

        }
        this.isEmailValid= false
    }
    handleKeyDown = function (e) {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            this.onUserLogin();
        }
    };
    onConfirmUser = () => {
        
       
        if ( this.state.token !== '' && this.isTokenValid) {
            localStorage.setItem('user_id', this.state.email);
            this.props.confirmUserWithPincode(this.state);
        }
        else {
            NotificationManager.error('Please enter a valid verification code');
        }

    }

    handlePinKeyDown = function (e) {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            this.onConfirmUser();
        }
    };
    onReset = () => {
        this.setState({
            alreadyHavePin:false,
            email:"",
            token: '',
        })
        this.props.resetLogin();
    }
    onPinChange(event) {
        var regex = /^\d{6}$/;
        this.setState({ token: event.target.value });
        if (regex.test(event.target.value)) {
            this.isTokenValid = true;

        }
    }
    alreadyHavePin=()=>{
        
       

        this.setState({
           email:localStorage.getItem('user_id'),
            alreadyHavePin:true
        })
    }


    render() {
        const { email, token } = this.state;
        const { loading, emailSent } = this.props;
        
        const responseGoogle = (response) => {
            var responseModel = {
                accessToken: response.accessToken,
                provider: 'google'
            }
            // Pass Email, Name, accesstoken and provider to Gravito Api for user registration/access
            if (responseModel.accessToken) {
                this.props.socialSignIn(responseModel);
            }

        }
        const responseFacebook = (response) => {
            var responseModel = {
                accessToken: response.accessToken,
                provider: 'facebook'
            }
            // Pass Email, Name, accesstoken and provider to Gravito Api for user registration/access
            if (responseModel.accessToken) {
                this.props.socialSignIn(responseModel);
            }
        }
        var formGroup = (
            <div>
                <div className="page-login-field top-15" onKeyDown={(e) => { this.handleKeyDown(e); }}>
                    <i className="fa fa-envelope"></i>
                    <input autoComplete="off" key="email" id="email" name= "email" type="email" placeholder="Enter your email address"
                        value={this.state.email}
                        onChange={(e) => { this.onEmailChange(e) }}
                        required
                    ></input>
                    <em>(required)</em>
                </div>
                <div style={justifyCenter}>
                    <Button className="submit-button bg-theme-color" onClick={this.onUserLogin}>Email me PIN to log in</Button>
                </div>
                {/* <div className="justify-center-link" onClick={this.alreadyHavePin} >
                       Already have PIN ?    
                 </div> */}
                
            </div>

        )
        if (emailSent || this.state.alreadyHavePin) {
            formGroup = (
                <div>
                    <div className="page-login-field top-15" onKeyDown={(e) => { this.handlePinKeyDown(e) }}>
                        <i className="fa fa-key"></i>
                        <input autoComplete="off" key="pin" id="pin" name= "pin"  type="number" placeholder="Enter Verification Code"
                            value={this.state.token}
                            onChange={(e) => { this.onPinChange(e) }}
                            required
                        ></input>
                        <em>(required)</em>

                    </div>
                    <div style={{...justifyCenter,marginBottom:"10px"}}>
                        <Button className="submit-button" onClick={this.onConfirmUser}>Confirm</Button>
                        <Button className="submit-button" onClick={this.onReset}>Reset</Button>
                    </div>
                </div>
            )
        }
        return (
            <>
                <div className="login-page-div">
                <div className="new-hider"></div>
                    <div className=" login-content-div page-login content-boxed content-boxed-padding top-0 bottom-50 bg-white">
                        <img className="preload-image login-bg responsive-image1 bottom-0 shadow-small" src={require("../../assets/images/logo.png")} alt="img"></img>
                        {/* <img className="preload-image login-image shadow-small" src={require('../../assets/images/gravito(G).png')} alt="img"></img> */}
                        <h1 className="color-black ultrabold top-20 bottom-5 font-30">Login</h1>
  
                        {formGroup}
                        <Dimmer active={loading}>
                            <Loader>Loading</Loader>
                        </Dimmer>

                        <div style={justifyCenter}>
                            <p className="bottom-10 top-10">
                            Or alternatively sign in with
                                 </p>
                        </div>
                        <div style={justifyCenter}>
                            <FacebookLogin
                                appId={AppConfig.facebookAppId}
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={responseFacebook}
                                render={renderProps => (
                                    <Button className="height-35" onClick={renderProps.onClick}  circular color='facebook' icon='facebook' />
                                )}
                            />
                            <GoogleLogin
                                clientId={AppConfig.googleClientId}
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                render={renderProps => (
                                    <Button className="height-35" onClick={renderProps.onClick}  circular color='google plus' icon='google plus' />
                                )}>
                            </GoogleLogin>
                        </div>
                        <div style={justifyCenter}>
                            <p className="bottom-10">By signing up you agree to Gravito
                                 </p>

                        </div>
                        <div style={justifyCenter}>
                            <NavLink to="/terms-conditions" className="bottom-10">Terms of Service</NavLink>
                        </div>
                        
                    </div>
                    
                </div>


            </>
        )
    }
}


const mapStateToProps = ({ authUser }) => {
    const { user, loading, emailSent } = authUser;
    return { user, loading, emailSent }
}

export default connect(mapStateToProps, {
    signin,
    authenticateUser,
    confirmUserWithPincode,
    resetLogin,
    signinUserSuccess,
    signinUserFailure,
    socialSignIn,
    socialSigninUserSuccess,
    socialSigninUserFailure
})(Login);