import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../../util/IntlMessages';
import { Dropdown, Select } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';

class ConsentSearch extends Component {
    
    render() {
       
        const placeholder = this.props.intl.formatMessage({id: 'component.searchconsents'});
       let classes="container search-input-container search-input-round search-input-dark bottom-15 hide"
       
       if( this.props.show){
           classes="container search-input-container search-input-round search-input-dark bottom-15 show"
       }
        return (
            <>
                
                <div style={{marginTop:"10px",paddingLeft:"0px",zIndex:"6",width:"100%"}} className={classes}>
				<div className="search-boxes responsive-search">
					<div className="search">
						<i className="fa fa-search"></i>
						<input value={this.props.consent} placeholder={placeholder} onChange={(e)=>{this.props.onchange(e)}}/>
                        {/* <a style={{marginTop:"5px",position:"absolute",right:"-61px",borderRadius:"17.5px"}} href="javascript:;" className="scale-hover icon icon-round no-border icon-xs bg-blue-dark border-blue-dark-3d"><i className="fa fa-download"></i></a> */}
					</div>
                   
				</div>
			</div>	
            </>
        )
    }
}
export default injectIntl(ConsentSearch) 